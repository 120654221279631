import React, { useRef }  from 'react';
import Button from '@material-ui/core/Button';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    DialogContentText,
    AppBar, Toolbar, IconButton, Typography, Paper
} from "@material-ui/core";
import { PayPalButton } from "react-paypal-button-v2";
import CloseIcon from "@material-ui/icons/Close";
import Cart from "./service/Cart";
import Site from "./service/Site";
import Util from "./service/Util";

import StripeCheckout from 'react-stripe-checkout';


class PaymentDialog extends React.Component {
    orderid=0
    inPlaceOrderProgress=false
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.state={
            show:false,
            cartChanged:'',
            alertOpen:false,
            alertMessage:"",
            showStripe: false,
            orderid:0,
            ppready:"" //"Please wait..."

        }
        this.onStripeToken = this.onStripeToken.bind(this);

    }
    showStripe(){
        Site.instance.getOrderId({}, (res, err) => {
            if (res > 0) {
                let norderid=res;
/*
                try{window.posthog.identify(Site.instance.auth.email,{ orderid: norderid, shop: Site.instance.site.sitename});}
                catch (e) {
                }
*/
                this.setState({orderid: norderid, showStripe: true}, ()=>{
                    document.getElementsByClassName('StripeCheckout')[0].style.visibility='hidden'
                    document.getElementsByClassName('StripeCheckout')[0].click()
                })
            } else {
                this.orderid = 0;
                Site.instance.showToast("Cannot place order " + err, "error", "bottom")
            }
        })

    }
    showPaypalButtons(){
        if (!window.paypal) {
            Site.instance.showToast("Paypal not ready. Contact restaurant.");
            return;
        }
        Site.instance.getOrderId({}, (res, err) => {
            if (res > 0) {
                this.orderid = res;
                window.paypal.Buttons(
                    {
                        createOrder: (data, actions)=>
                        {
                            try {
                                let amount = Site.instance.isTestOrder() ? 0.01 : Cart.instance.totalCartValue
                                Cart.instance.saveToStorage()
                                let createdOrder = actions.order.create({
                                    purchase_units: [{
                                        amount: {
                                            currency_code: "GBP",
                                            value: amount
                                        },
                                        invoice_id: 'Order #' + Site.instance.site.siteid + "#" + this.orderid,
                                    }]
                                });
                                return createdOrder
                            }
                            catch (e) {
                                let data = {
                                    err: e.message,
                                    stacktrace: Util.instance? Util.instance.getStackTrace(): ''
                                }
                                Site.instance.sendEventLog({data: JSON.stringify(data), event: "ppcreateordererr"})
                                return null
                            }
                        },
                        onApprove: (details, actions) => {
                            Site.instance.showWaiting(true, "Please wait ...");
                            Site.instance.completeOrder(
                                this.orderid, { paydetails: details}, (orderid, message) => {
                                    Site.instance.showWaiting(false, "");
                                    if (orderid === 0) {
                                        Site.instance.sendEventLog({data: message, event: "onapproveerror"})
                                        Site.instance.showToast(message, "error", "bottom");
                                    } else {
                                        Site.instance.sendEventLog({data: message, event: "onapproveok"})
                                        this.setState({alertOpen: true, alertMessage: message, alertOrderNo: orderid})
                                    }
                                });
                        },
                        onError:(err)=>{
                            let data = {
                                err: err && err.message? err.message : err,
                                stacktrace: Util.instance? Util.instance.getStackTrace(): ''
                            }
                            Site.instance.sendEventLog({data: JSON.stringify(data), event: "pperror"})
                            Site.instance.showToast("Payment error. Try another browser or payment method", "error", "bottom")
                        }
                    }).render('#paypalid1');
            } else {
                this.orderid = 0;
                Site.instance.showToast("Cannot place order " + err, "error", "bottom")
            }
        })
    }

    onStripeToken(token){
        Site.instance.showWaiting(true, "Please wait ...");
        Site.instance.completeOrder(
            this.state.orderid, { stripe_token: token.id}, (orderid, message) => {
                Site.instance.showWaiting(false, "");
                if (orderid === 0) {
                    Site.instance.sendEventLog({data: message, event: "onapproveerror"})
                    Site.instance.showToast(message, "error", "bottom");
                    this.setState({showStripe: false, show: false})
                } else {
                    Site.instance.sendEventLog({data: message, event: "onapproveok"})
                    this.setState({alertOpen: true, alertMessage: message, alertOrderNo: orderid, showStripe:false})
                }
            });

        /*Site.instance.completeOrder(
            this.orderid, { paydetails: details}, (orderid, message) => {
                Site.instance.showWaiting(false, "");
                if (orderid === 0) {
                    Site.instance.sendEventLog({data: message, event: "onapproveerror"})
                    Site.instance.showToast(message, "error", "bottom");
                } else {
                    Site.instance.sendEventLog({data: message, event: "onapproveok"})
                    this.setState({alertOpen: true, alertMessage: message, alertOrderNo: orderid})
                }
            });
        */
        console.log(token);
/*
        fetch('/saveOrder', {
            method: 'POST',
            body: JSON.stringify(token),
        }).then(response => {
            response.json().then(data => {
                alert(`We are in business, ${data.email}`);
            });
        });
*/
    }

    componentDidMount() {
        Cart.instance.showDialogSubject.subscribe((e)=>{
            if (e.name && (e.name==="PaymentDialog" || e.name==="*")) {
                this.orderid=0;
                window.hj('event', 'startcapture')
                this.setState({show: e.show, alertOpen: false, alertMessage: ""},()=>{
                    if (Cart.instance.paymentMethod === "Online" && Cart.instance.totalCartValue>0){
                        if (Site.instance.site.payment.toLowerCase().contains('stripe')){
                            this.showStripe()
                        }
                        if (Site.instance.site.payment.toLowerCase().contains('paypal')){
                            setTimeout(() => {
                                this.showPaypalButtons()
                            }, 500);
                        }
                    }
                });
            }
        })
        Cart.instance.cartSubject.subscribe(()=>{
            this.setState({cartChanged:''})
        })
    }

    render()
        {
            return (
                this.state.show &&
                        <React.Fragment>
                            {
                                this.state.showStripe &&
                            <StripeCheckout
                                ref={c=>this.myRef=c}
                                amount={(100*(Site.instance.isTestOrder() ? 0.01 : Cart.instance.totalCartValue)).toFixed(0)}
                                name="Online Payment"
                                // functions defined above can be used to add more information while making the API call.
                                // description={`Order of ${computeQuantity(cart)} items!`}
                                //image='LINKTOIMAGE'
                                email={Site.instance.auth._u.username}
                                stripeKey={Site.instance.site.stripe_pk}
                                currency="GBP"
                                token={this.onStripeToken}/>
                            }
                            {!this.state.showStripe && <Dialog  open={true} fullWidth={true} onClose={() => this.setState({show:false})}
                                 style={{maxWidth: '960px', margin: '0 auto'}}
                                 className="minWidth300"
                        >
                            <AppBar style={{position:"sticky", top:0}}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit"
                                                onClick={() => this.setState({show:false})}
                                                aria-label="close">
                                        <CloseIcon/>
                                    </IconButton>
                                    <Typography variant="h6" style={{flexGrow: 1}}>
                                        Payment
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                {Cart.instance.paymentMethod === "Online" && Cart.instance.totalCartValue>0?
                                    <React.Fragment>
                                        <Grid container justify = "center" >
                                            <Typography variant="h6" align="center" style={{ padding:30}}>
                                                {this.state.ppready}
                                            </Typography>
                                        </Grid>
                                        <div id="paypalid1"></div>

                                        {/*<div style={{visibility: `${this.state.ppready===""? "visible":"hidden"}`}}>
                                    <PayPalButton
                                        onError={(error) => {
                                            Site.instance.sendEventLog({data: JSON.stringify(error), event: "paypalerror"})
                                            Site.instance.showToast("Error in connecting to Paypal", "error", "bottom")
                                            this.setState({ppready:"Error in connecting to Paypal"})
                                        }}

                                        onButtonReady={() => {
                                            let iframes = window.document.getElementsByTagName('iframe');
                                            for(let i=0; i<iframes.length; i++)
                                                iframes[i]['data-hj-allow-iframe']=1;
                                            Site.instance.sendEventLog({data: "", event: "paypalready"})
                                            Site.instance.getOrderId({}, (res, err) => {
                                                if (res > 0) {
                                                    this.orderid = res;
                                                    this.setState({ppready:""})
                                                } else {
                                                    this.orderid = 0;
                                                    this.setState({ppready:"Cannot place order " + err})
                                                    Site.instance.showToast("Cannot place order " + err, "error", "bottom")
                                                }
                                            })
                                        }}
                                        shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                        currency={"GBP"}
                                        createOrder={(data, actions) => {
                                            Site.instance.sendEventLog({data: "" + this.orderid, event: "createorder"})
                                            return actions.order.create({
                                                purchase_units: [{
                                                    amount: {
                                                        currency_code: "GBP",
                                                        value: Cart.instance.totalCartValue
                                                    },
                                                    invoice_id: 'Order #' + Site.instance.site.siteid + "#" + this.orderid,
                                                    custom_id: Site.instance.site.siteid
                                                }],
                                                application_context: {
                                                    shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                                                }
                                            });
                                        }}
                                        onApprove={(details, actions) => {
                                            Site.instance.sendEventLog({data: JSON.stringify(details), event: "onapprove"})
                                            Site.instance.completeOrder(
                                                this.orderid, { paydetails: details}, (orderid, message) => {
                                                if (orderid === 0) {
                                                    Site.instance.sendEventLog({data: message, event: "onapproveerror"})
                                                    Site.instance.showToast(message, "error", "bottom");
                                                } else {
                                                    Site.instance.sendEventLog({data: message, event: "onapproveok"})
                                                    this.setState({alertOpen: true, alertMessage: message, alertOrderNo: orderid})
                                                }
                                            });
                                        }}
                                        options={{
                                            clientId: Site.instance.site.ppclientid,
                                            currency: "GBP"
                                        }}

                                    /></div>*/}
                                    </React.Fragment>
                                    :
                                    <Grid container justify = "center">
                                        <Typography variant="h6" align="center" style={{ padding:30, margin:"20px 0"}}>
                                        You have chosen to pay with cash. You can place your order now.
                                        </Typography>
                                    </Grid>

                                }
                            </DialogContent>
                            <DialogActions>
                                <Button color="secondary" onClick={() => {
                                    this.setState({show:false})
                                }}>Cancel</Button>
                                {Cart.instance.paymentMethod === "Cash" ? <Button color="secondary" onClick={() => {

                                    if (Cart.instance.totalCartValue<=0) return;
                                        Site.instance.showWaiting(true, "Please wait ...");
                                        Site.instance.getOrderId({}, (res, err) => {
                                            Site.instance.showWaiting(false, "");
                                            this.orderid = res;
                                            if (res > 0) {
                                                Site.instance.completeOrder( this.orderid, {}, (orderid, message) => {
                                                    if (orderid === 0) {
                                                        Site.instance.showToast(message, "error", "bottom");
                                                    } else {
                                                        this.setState({alertOpen: true, alertMessage: message, alertOrderNo: orderid})
                                                    }
                                                });
                                            } else {
                                                Site.instance.showToast("Cannot place order " + err, "error", "bottom")
                                            }
                                        })
                                    }}>Place Order</Button>
                                    : ""
                                }
                            </DialogActions>
                        </Dialog>}
                            <Dialog
                                open={this.state.alertOpen}
                                    onClose={()=>{
                                    this.setState({alertOpen:false, show:false})
                                }}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle>Order No: {this.state.alertOrderNo}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText >
                                        {this.state.alertMessage}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={()=>{
                                        this.setState({alertOpen:false, show:false})
                                    }} color="primary" autoFocus>
                                        OK
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </React.Fragment>
            );
        }
}
export default PaymentDialog;
