import React from 'react';
import {makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import {
    AppBar,
    Badge,
    Toolbar,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemText,
    DialogContent,
    Container, BottomNavigation, Grid, Box, Button, Dialog, Card, CardContent, ListItemSecondaryAction, DialogActions
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MenuIcon from '@material-ui/icons/Menu';
import {ShoppingBasketOutlined, Person, PersonOutline, MotorcycleOutlined} from '@material-ui/icons';
import AppContext from '../AppContext'
import CloseIcon from "@material-ui/icons/Close";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Util from './service/Util.js'
import Cart from './service/Cart.js'
import Site from './service/Site'

class OrderHistoryDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            show: false,
            orders: [],
            error:''
        }
    }
    componentDidMount() {
        Cart.instance.showDialogSubject.subscribe((e)=>{
            if (e.name && e.name==="OrderHistoryDialog") {
                Site.instance.fetchOrderHistory((result, data)=>{
                    if (result===1){
                        let offerdetails = [];
                        Site.instance.foodinfo.offers.map(offer=>{
                            offerdetails = offerdetails.concat(offer.details);
                        })
                        data.map((order)=>{
                            order.details.map(line=>{
                                if (line.foodid_1*1>0){
                                    let food = Site.instance.foodinfo.foods.find(f=>f.foodid*1===line.foodid_1*1);
                                    line.foodtitle=food?food.title:"";
                                }
                                if (line.offerdetailid*1>0){
                                    let offerdetail = offerdetails.find(f=>f.offerdetailid*1===line.offerdetailid*1);
                                    let offerid = offerdetail? offerdetail.offerid*1 : 0;
                                    if (offerid>0){
                                        let offer = Site.instance.foodinfo.offers.find(f=>f.offerid*1===offerid);
                                        line.offertitle=offer?offer.title:"";
                                    }
                                }
                            })
                        })
                        this.setState({show: e.show, orders: data, error:''});
                    }else{
                        this.setState({orders: [], error:"Error in displaying previous orders"});
                    }

                })
            }
        })
    }

    render() {
        return (
            this.state.show &&
                    <Dialog fullWidth={false}
                            open={true} onClose={() => this.setState({show:false})}
                    style={{minWidth: 300}}
                    >

                        <AppBar position={"static"}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit"
                                            onClick={() => this.setState({show:false})}
                                            aria-label="close">
                                    <CloseIcon/>
                                </IconButton>
                                <Typography variant="h6" style={{flexGrow: 1}}>
                                    Order History
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <DialogContent style={{marginTop:10, padding:0}}>
                            {this.state.orders.length===0 &&
                            <Typography variant="h6" style={{margin: "40px auto", textAlign:"center", width:250}}>
                                No Order History
                            </Typography>
                            }
                        {this.state.orders.map((order) => (
                            <Card key={order.orderid} style={{margin: 10}}>
                                <CardContent>
                                    <Typography variant="h7" component="h5" style={{textAlign:"center"}}>
                                        Order# {order.orderid}   ○ {order.ordertime}
                                    </Typography>
                                    <List dense style={{padding:0}}>
                                    {order.details.map((line) => (
                                        <ListItem key={line.orderdetailid}>
                                            <ListItemText primary={
                                                <span style={{fontSize: "0.8rem"}}>{line.quantity*1}x{line.foodid_1*1>0 && line.foodtitle} {line.offerdetailid*1>0 && line.offertitle}</span>
                                            }
                                            />
                                            <ListItemSecondaryAction>
                                                <span style={{fontSize: "0.8rem"}}>{Util.instance.formatPrice(line.unitprice)}</span>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        ))}
                                    </List>
                                    <List  dense style={{padding:0}}>
                                            <ListItem key="sub" >
                                                <ListItemText/>
                                                <ListItemSecondaryAction>
                                                    <span style={{fontSize: "0.8rem"}}>Subtotal: {Util.instance.formatPrice(order.totalvalue)}</span>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        <ListItem key="deliveryCharge">
                                            <ListItemText/>
                                            <ListItemSecondaryAction>
                                                <span style={{fontSize: "0.8rem"}}>DeliveryCharge: {Util.instance.formatPrice(order.deliverycharge)}</span>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem key="discount">
                                            <ListItemText/>
                                            <ListItemSecondaryAction>
                                                <span style={{fontSize: "0.8rem"}}>Discount: {Util.instance.formatPrice(order.voucher)}</span>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem key="total" >
                                            <ListItemText/>
                                            <ListItemSecondaryAction>
                                                <span style={{fontWeight:"bold",fontSize: "0.8rem"}}>Total: {Util.instance.formatPrice(order.amountdue)}</span>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem key="notes" >
                                            <ListItemText primary={
                                                <Typography variant="body2" style={{fontSize: "0.8rem"}}>
                                                {order.paystatus*1===1 && "Paid "}
                                            {order.paystatus*1===2 && ""}
                                            {order.paymenttypeid*1===31 && "Online "}
                                            {order.paymenttypeid*1===30 && "Cash "}
                                            {order.ordertype*1===1 && "Collection "}
                                            {order.ordertype*1===2 && "Delivery "}
                                                <br />
                                            {Site.instance.getCustomerAddress()}
                                                </Typography>}
                                            />
                                        </ListItem>
                                    </List>
                                </CardContent>
                            </Card>
                        ))}
                        </DialogContent>
                        <DialogActions>
                            <Button color="secondary" onClick={() => {
                                this.setState({show:false})
                            }}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
        );
    }
}

export default OrderHistoryDialog;