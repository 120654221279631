import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    AppBar,
    Badge,
    Toolbar,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemText,
    Link,
    DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, Dialog
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import MenuIcon from '@material-ui/icons/Menu';
import {ShoppingBasketOutlined, Person, PersonOutline, MotorcycleOutlined} from '@material-ui/icons';
import AppContext from '../AppContext'
import Site from './service/Site'
import Cart from "./service/Cart";
import Util from "./service/Util";


class LoginDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showForRegister: false,
        }
    }
    componentDidMount() {
        Cart.instance.showDialogSubject.subscribe((e)=>{
            if (e.name && e.name==="LoginDialog")
                this.setState({show:e.show, showForRegister:false});
        })
    }

    handleRegisterClick() {
        if (!this.state.showForRegister) {
            this.setState({showForRegister: true}, ()=>{
                this.registerEmail.focus();
            })

        }
        else if (this.state.showForRegister) {
            if (this.registerEmail2.value !== this.registerEmail.value) {
                this.registerEmail.focus()
                Site.instance.showToast("Confirm your email address", "error", "bottom");
                return;
            }
            if (!Util.instance.validateEmail(this.registerEmail.value)) {
                Site.instance.showToast("Invalid email address", "error", "bottom");
                return;
            }
            if (this.registerPass.value ==="") {
                this.registerPass.focus()
                Site.instance.showToast("Enter your password", "error", "bottom");
                return;
            }
            if (this.registerPass.value !== this.registerPass2.value) {
                this.registerPass.focus()
                Site.instance.showToast("Confirm your password", "error", "bottom");
                return;
            }
            if (this.registerFirstname.value==="") {
                this.registerFirstname.focus()
                Site.instance.showToast("Enter your first name", "error", "bottom");
                return;
            }
            if (this.registerLastname.value==="") {
                this.registerLastname.focus()
                Site.instance.showToast("Enter your last name", "error", "bottom");
                return;
            }
            if (this.registerPhone.value==="") {
                this.registerPhone.focus()
                Site.instance.showToast("Enter your phone number", "error", "bottom");
                return;
            }
            if (this.registerStreet.value==="") {
                this.registerStreet.focus()
                Site.instance.showToast("Enter address line", "error", "bottom");
                return;
            }
            if (this.registerTown.value==="") {
                this.registerTown.focus()
                Site.instance.showToast("Enter town", "error", "bottom");
                return;
            }
            if (this.registerPostcode.value==="" || !Site.instance.checkPostCode(this.registerPostcode.value)) {
                this.registerPostcode.focus()
                Site.instance.showToast("Enter valid Postcode", "error", "bottom");
                return;
            }


            let data = {};
            data.email = this.registerEmail.value;
            data.password = this.registerPass.value;
            data.phone = this.registerPhone.value;
            data.firstname = this.registerFirstname.value;
            data.lastname = this.registerLastname.value;
            data.postcode =  this.registerPostcode.value;
//            data.flatnumber = this.registerFlatnumber.value;
//            data.housenumber = this.registerHousenumber.value;
//            data.housename = this.registerHousename.value;
            data.street = this.registerStreet.value;
            data.town = this.registerTown.value;
            data.addressnotes = this.registerAddressnotes.value;
            Site.instance.registerUser(data, (result, msg)=>{
                if (result===1) {
                    this.setState({show: false})
                }
            });

        }
    }

    checkAuth(){
        Site.instance.checkAuth(this.loginEmail.value, this.loginPass.value, (result, msg)=>{
            if (result===0)
                Site.instance.showToast("Login Error " + msg, "error", "bottom");
            else if (result===1){
                this.setState({show:false})
            }
        })
    }

    render() {
        return (
            this.state.show &&
                    <Dialog open fullScreen={false} onClose={() => this.setState({show:false})}>
                        <DialogTitle>{!this.state.showForRegister ? "Login" : "Register"}</DialogTitle>
                        {!this.state.showForRegister ?
                            <DialogContent>
                                <TextField
                                    autoFocus
                                    size="small"
                                    margin="dense"
                                    id="email"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                    inputRef={e => this.loginEmail = e}
                                />
                                <TextField
                                    margin="dense"
                                    size="small"
                                    id="pass"
                                    label="Password"
                                   type="password"
                                   inputRef={e => this.loginPass = e}
                                   fullWidth

                                />
                                <Button  margin="dense"
                                        size="small"
                                         fullWidth
                                       onClick={()=>{
                                           this.setState({show:false});
                                           Cart.instance.showDialogSubject.next({name: "ForgotPassDialog", show:true});
                                       }}
                                         color="primary"
                                         style={{justifyContent:"flex-end"}}
                                >Forgot Password <ArrowForwardIosIcon/></Button>
                            </DialogContent>
                            :
                            <DialogContent>
                                <TextField
                                    style={{marginTop:0, marginBottom:0}}
                                    autoFocus
                                    size="small"
                                    margin="dense"
                                    label="Email Address"
                                    type="email"
                                    fullWidth
                                    inputRef={e => this.registerEmail = e}
                                />
                                <TextField
                                    style={{marginTop:0, marginBottom:0}}
                                    margin="dense"
                                    size="small"
                                    id="email2"
                                    label="Email Address Confirm"
                                    type="email"
                                    fullWidth
                                    inputRef={e => this.registerEmail2 = e}
                                />
                                <TextField
                                    style={{marginTop:0, marginBottom:0}}
                                    margin="dense"
                                    size="small"
                                    label="Password"
                                    type="password"
                                    fullWidth
                                    inputRef={e => this.registerPass = e}
                                />
                                <TextField
                                    style={{marginTop:0, marginBottom:0}}
                                    margin="dense"
                                    size="small"
                                    id="pass2"
                                    label="Password Confirm"
                                    type="password"
                                    fullWidth
                                    inputRef={e => this.registerPass2 = e}
                                />
                                <TextField
                                    style={{marginTop:0, marginBottom:0}}
                                    margin="dense"
                                    size="small"
                                    label="First Name"
                                    type="text"
                                    fullWidth
                                    inputRef={e => this.registerFirstname = e}
                                />
                                <TextField
                                    style={{marginTop:0, marginBottom:0}}
                                    margin="dense"
                                    size="small"
                                    label="Last Name"
                                    type="text"
                                    fullWidth
                                    inputRef={e => this.registerLastname = e}
                                />
                                <TextField size="small" label="Phone" type="phone" fullWidth
                                           inputRef={e => this.registerPhone = e}
                                           style={{marginTop:0, marginBottom:0}}/>
                                <TextField size="small" label="Address" type="text" fullWidth
                                           helperText="Address line"
                                           inputRef={e => this.registerStreet = e}
                                           style={{marginTop:0, marginBottom:0}}/>
                                <TextField size="small" label="Town" type="text" fullWidth
                                           inputRef={e => this.registerTown = e}
                                           style={{marginTop:0, marginBottom:0}}/>
                                <TextField size="small" label="Postcode" type="postcode" fullWidth
                                           inputRef={e => this.registerPostcode = e}
                                           style={{marginTop:0, marginBottom:0}}/>
                                <TextField size="small" label="Address Notes" type="text" fullWidth
                                           inputRef={e => this.registerAddressnotes = e}
                                           style={{marginTop:0, marginBottom:0}}/>

                            </DialogContent>
                        }
                        <DialogActions>
                            <Button color="secondary" onClick={() => {
                                this.setState({show:false})
                            }}>Cancel</Button>
                            <Button color="secondary" onClick={() => {
                                this.handleRegisterClick()
                            }}>Register</Button>
                            {!this.state.showForRegister ? <Button color="primary" onClick={() => {
                                this.checkAuth()
                            }}>Login</Button> : ""}
                        </DialogActions>


                        {/*
                <Dialog fullScreen open={true} onClose={()=>app.handleCloseLoginDialog(false)} >
                <Grid spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh' , padding: 10, margin: 10}}
                >
                <Grid item xs={12}>
                <Grid container spacing={8} alignItems="flex-end">
                <Grid item md={true} sm={true} xs={true}>
                <TextField id="username" label="Username" type="email" fullWidth autoFocus required />
                </Grid>
                </Grid>
                <Grid container spacing={8} alignItems="flex-end">
                <Grid item md={true} sm={true} xs={true}>
                <TextField id="username" label="Password" type="password" fullWidth required />
                </Grid>
                </Grid>
                <Grid container alignItems="center" justify="space-between">
                <Grid item>
                <FormControlLabel control={
                <Checkbox
                color="primary"
                />
                } label="Remember me" />
                </Grid>
                <Grid item>
                <Button disableFocusRipple disableRipple style={{ textTransform: "none" }} variant="text" color="primary">Forgot password ?</Button>
                </Grid>
                </Grid>
                <Grid container justify="center" style={{ marginTop: '10px' }}>
                <Button variant="outlined" color="primary" style={{ textTransform: "none" }}>Login</Button>
                </Grid>
                </Grid>
                </Grid>
                */}
                    </Dialog>
        );
    }
}

export default LoginDialog;
