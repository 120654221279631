import React from 'react';
import {
    Backdrop, CircularProgress
} from "@material-ui/core";
import Site from "./service/Site";


class WaitingProgress extends React.Component {
    timer;
    constructor(props) {
        super(props);
        this.state={
            show:false,
            message: "",
        }
        Site.instance.waitingSubject.subscribe((data)=>{
            this.setState({
                show: data.show,
                message: data.msg?? ""
            });
        })
    }

    render() {
        return (
                this.state.show &&
                <Backdrop open style={{zIndex: 9999, backgroundColor:"rgb(0 0 0 / 70%)"}} >
                    <span style={{ alignItems: "center", display: "flex",  fontSize: "larger", flexDirection:"column"}}>
                    <CircularProgress color="secondary" />
                    <span style={{marginTop: 10, color: "#e6e6e6"}}>{this.state.message}</span>
                    </span>
                </Backdrop>
    );
    }
}

export default WaitingProgress;