import React from 'react';
import {createMuiTheme, makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import {
    AppBar,
    Badge,
    Toolbar,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemText,
    Grid,
    Button, BottomNavigation, Snackbar, Container, Dialog, DialogTitle, DialogContent, DialogActions
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import Site from "./service/Site";



class MessageBox extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            show:false,
            message: "",
            title:""
        }
        Site.instance.messageBoxSubject.subscribe((msg)=>{
            if (msg.msg){
                    this.setState({
                        show: true,
                        message: msg.msg,
                        title: msg.title,
                    });
                }
        })
    }

    render() {
        return (
                this.state.show &&

                <Dialog open={true}>
                    <DialogTitle>
                        {this.state.title}
                    </DialogTitle>
                    <DialogContent style={{ minWidth: 250, minHeight: 150}}>
                        <Typography component={"body2"}>
                            {this.state.message}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={()=>{
                            this.setState({show: false})
                        }} >OK</Button>
                    </DialogActions>
                </Dialog>

    );
    }
}

export default MessageBox;