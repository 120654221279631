import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    AppBar,
    Badge,
    Toolbar,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemText,
    Link,
    DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, Dialog
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import MenuIcon from '@material-ui/icons/Menu';
import {ShoppingBasketOutlined, Person, PersonOutline, MotorcycleOutlined} from '@material-ui/icons';
import AppContext from '../AppContext'
import Site from './service/Site'
import Cart from "./service/Cart";
import Util from "./service/Util";


class ProfileDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        }
    }
    componentDidMount() {
        Cart.instance.showDialogSubject.subscribe((e)=>{
            if (e.name && e.name==="ProfileDialog")
                this.setState({show:e.show});
        })
    }

    handleUpdateClick() {
        if (this.registerPass.value!=='' && this.registerPass.value !== this.registerPass2.value) {
            Site.instance.showToast("Confirm your password", "error", "bottom");
            return;
        }
        if (this.registerFirstname.value==="") {
            this.registerFirstname.focus()
            Site.instance.showToast("Enter your first name", "error", "bottom");
            return;
        }
        if (this.registerLastname.value==="") {
            this.registerLastname.focus()
            Site.instance.showToast("Enter your last name", "error", "bottom");
            return;
        }
        if (this.registerPhone.value==="") {
            this.registerPhone.focus()
            Site.instance.showToast("Enter your phone number", "error", "bottom");
            return;
        }
        if (this.registerStreet.value==="") {
            this.registerStreet.focus()
            Site.instance.showToast("Enter address line", "error", "bottom");
            return;
        }
        if (this.registerTown.value==="") {
            this.registerTown.focus()
            Site.instance.showToast("Enter town", "error", "bottom");
            return;
        }
        if (this.registerPostcode.value==="" || !Site.instance.checkPostCode(this.registerPostcode.value)) {
            this.registerPostcode.focus()
            Site.instance.showToast("Enter valid Postcode", "error", "bottom");
            return;
        }

        let data = {};
        data.update=1;
        data.email=Site.instance.auth._u.username;
        data.password = this.registerPass.value===""? Site.instance.auth._u.pass : this.registerPass.value;
        data.phone = this.registerPhone.value;
        data.firstname = this.registerFirstname.value;
        data.lastname = this.registerLastname.value;
        data.postcode =  this.registerPostcode.value;
//            data.flatnumber = this.registerFlatnumber.value;
//            data.housenumber = this.registerHousenumber.value;
//            data.housename = this.registerHousename.value;
        data.street = this.registerStreet.value;
        data.town = this.registerTown.value;
        data.addressnotes = this.registerAddressnotes.value;
        Site.instance.updateUserProfile(data, (result, msg)=>{
            if (result===1) {
                this.setState({show: false})
            }
        });
    }

    render() {
        return (
            this.state.show &&
                    <Dialog open fullWidth onClose={() => this.setState({show:false})}>
                        <DialogTitle>Profile</DialogTitle>
                            <DialogContent>
                                <TextField
                                    size="small"
                                    margin="dense"
                                    label="First Name"
                                    type="text"
                                    fullWidth
                                    inputRef={e => {this.registerFirstname = e; if (e && Site.instance.auth) e.value=Site.instance.auth.firstname}}
                                />
                                <TextField
                                    size="small"
                                    margin="dense"
                                    label="Last Name"
                                    type="text"
                                    fullWidth
                                    inputRef={e => {this.registerLastname = e; if (e && Site.instance.auth) e.value=Site.instance.auth.lastname}}
                                />
                                <TextField label="Phone" type="phone" fullWidth size="small"
                                           inputRef={e => {this.registerPhone = e; if (e && Site.instance.auth) e.value=Site.instance.auth.phone}}/>
                                <TextField label="Address" type="text" fullWidth size="small"
                                           helperText="Address line"
                                           inputRef={e => {this.registerStreet = e; if (e && Site.instance.auth) e.value=Site.instance.auth.street}}/>
                                <TextField label="Town" type="text" fullWidth size="small"
                                           inputRef={e => {this.registerTown = e; if (e && Site.instance.auth) e.value=Site.instance.auth.town}}/>
                                <TextField label="Postcode" type="postcode" fullWidth size="small"
                                           inputRef={e => {this.registerPostcode = e; if (e && Site.instance.auth) e.value=Site.instance.auth.postcode}}/>
                                <TextField label="Address Notes" type="text" fullWidth size="small"
                                           inputRef={e => {this.registerAddressnotes = e; if (e && Site.instance.auth) e.value=Site.instance.auth.addressnotes}}/>

                                <TextField
                                    size="small"
                                    margin="dense"
                                    label="Password"
                                    type="password"
                                    fullWidth
                                    inputRef={e => this.registerPass = e}
                                    helperText="Enter new password or leave it blank to keep it unchanged"
                                />
                                <TextField
                                    size="small"
                                    margin="dense"
                                    id="pass2"
                                    label="Password Confirm"
                                    type="password"
                                    fullWidth
                                    inputRef={e => this.registerPass2 = e}
                                />

                            </DialogContent>
                        <DialogActions>
                            <Button color="secondary" onClick={() => {
                                this.setState({show:false})
                            }}>Cancel</Button>
                            <Button color="secondary" onClick={() => {
                                this.handleUpdateClick()
                            }}>Update</Button>
                        </DialogActions>
                    </Dialog>
        );
    }
}

export default ProfileDialog;