import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    AppBar,
    Badge,
    Toolbar,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemText,
    Drawer,
    ListItemIcon, Divider, Container, BottomNavigation, Grid
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import MenuIcon from '@material-ui/icons/Menu';
import {ShoppingBasketOutlined, Person, PersonOutline, MotorcycleOutlined} from '@material-ui/icons';
import AppContext from '../AppContext'
import Util from './service/Util.js'
import Site from './service/Site.js'
import Cart from "./service/Cart";
import InboxIcon from "@material-ui/icons/Inbox";
import MailIcon from "@material-ui/icons/Mail";
import HistoryIcon from '@material-ui/icons/History';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        flexGrow: 1,
        color: 'white',
    },
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

class LeftDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            show: false,
            siteChange:'',
            authChange:''
        }
    }
    componentDidMount() {
        Site.instance.siteSubject.subscribe(()=>{
            this.setState({siteChange: ''})
        })
        Site.instance.authSubject.subscribe(()=>{
            this.setState({authChange: ''})
        })
        Cart.instance.showDialogSubject.subscribe((e)=>{
            if (e.name==="LeftDrawer") {
                this.setState({show: !this.state.show});
            }
        })

    }

    render() {
        let site = Site.instance.site;
        let auth = Site.instance.auth;
        return (
            <Drawer anchor={"left"} open={this.state.show} onClose={()=>{
                this.setState({show:false})
            }}
                    PaperProps={{
                        style: {minWidth:300, minHeight:'100vh'}
                    }}
            >
                {Site.instance.isLogin() &&
                    <List style={{width: 'auto', height:'100%'}} >
                        <ListItem button key="logo" onClick={() => {
                            Cart.instance.showDialogSubject.next({
                                name: "ProfileDialog",
                                show: true
                            })
                        }}>
                            <ListItemText primary={
                            <React.Fragment>
                                <AccountCircleOutlinedIcon style={{fontSize:100, color:'#555555'}}/>
                                <Typography>{Site.instance.auth.firstname} {Site.instance.auth.lastname}</Typography>
                                <Typography>{Site.instance.auth.email}</Typography>
                            </React.Fragment>
                            } style={{alignContent:"center", textAlign:"center"}}/>
                        </ListItem>
                        <ListItem button key="profile" onClick={() => {
                            this.setState({show:false})
                            Cart.instance.showDialogSubject.next({
                                name: "ProfileDialog",
                                show: true
                            })
                        }}>
                            <ListItemIcon><AccountCircleIcon/></ListItemIcon>
                            <ListItemText primary={"Profile"}/>
                        </ListItem>
                        <ListItem button key="history" onClick={() => {
                            this.setState({show:false})
                            Cart.instance.showDialogSubject.next({
                                name: "OrderHistoryDialog",
                                show: true
                            })
                        }}>
                            <ListItemIcon><HistoryIcon/></ListItemIcon>
                            <ListItemText primary={"Order History"}/>
                        </ListItem>
                        <ListItem button key="logout" onClick={() => {
                            this.setState({show:false});
                            Site.instance.logout();
                        }}>
                            <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                            <ListItemText primary={"Logout"}/>
                        </ListItem>
                    </List>
                }
                {!Site.instance.isLogin() &&

                    <List style={{width: 'auto', height:'100%'}}>
                        <ListItem button key="logo" onClick={() => {
                            this.setState({show:false})
                            Cart.instance.showDialogSubject.next({
                                name: "LoginDialog",
                                show: true
                            })
                        }}>
                            <ListItemText primary={
                                <React.Fragment>
                                    <AccountCircleOutlinedIcon style={{fontSize:100, color:'#555555'}}/>
                                    <Typography>Not logged in</Typography>
                                </React.Fragment>
                            } style={{alignContent:"center", textAlign:"center"}}/>
                        </ListItem>
                        <ListItem button key="login" onClick={() => {
                            this.setState({show:false})
                            Cart.instance.showDialogSubject.next({
                                name: "LoginDialog",
                                show: true
                            })
                        }}>
                            <ListItemIcon><AccountCircleIcon/></ListItemIcon>
                            <ListItemText primary={"Login/Register"}/>
                        </ListItem>
                    </List>
                }
                <BottomNavigation
                    style={{
                        width: '100%',
                        position: 'sticky',
                        bottom: 0,
                        marginTop: 5,
                    }}>
                    <Grid justify="center" container
                          spacing={0}
                          direction="row"
                          alignItems="center"
                    >
                        <Typography component={"h5"} style={{fontSize: "smaller", color: '#999999'}}>RAMTD App Version: 1.0</Typography>
                    </Grid>
                </BottomNavigation>
            </Drawer>
        );
    }
}

export default LeftDrawer;