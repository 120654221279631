import React from 'react';
import {makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import {
    AppBar,
    Badge,
    Toolbar,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemText,
    Container, BottomNavigation, Grid, Box, Button, Dialog, DialogActions
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MenuIcon from '@material-ui/icons/Menu';
import {ShoppingBasketOutlined, Person, PersonOutline, MotorcycleOutlined} from '@material-ui/icons';
import AppContext from '../AppContext'
import CloseIcon from "@material-ui/icons/Close";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Util from './service/Util.js'
import Cart from './service/Cart.js'
import Site from './service/Site.js'
import ReactDOM from 'react-dom'

class CartDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            show:false,
            cartChanged:''
        }
        this.myContainer1 = React.createRef();

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        try{
            //Cart.instance.debugOrderText = this.myContainer1.current.innerText
        }
        catch (e) {
            console.log(e)
        }
    }

    componentDidMount() {
        Cart.instance.showDialogSubject.subscribe((e)=>{
            if (e.name && e.name==="CartDialog")
                this.setState({show:e.show});
        })
        Cart.instance.cartSubject.subscribe(()=>{
                this.setState({cartChanged:''});
        })

    }
    handleCheckout(){

        if (!Cart.instance.checkBeforeCheckoutProceed())
            return;

        if (Site.instance.isLogin()) {
            this.setState({show: false});
            Cart.instance.showDialogSubject.next({
                name: "DeliveryDialog",
                show: true
            })
        }else{
            Cart.instance.showDialogSubject.next({
                name: "LoginDialog",
                show: true
            })

        }
    }

    render() {

        return (
            this.state.show &&
                    <Dialog fullWidth={true}
                            open={true} onClose={() => this.setState({show:false})}
                            className="minWidth300"
                    >
                        <Grid container
                              spacing={0}
                              direction="column"
                              alignItems="center"
                              justify="flex-start"
                              alignContent={"center"}
                        >
                            <Container maxWidth="md" style={{margin: "0", padding: "0"}} id="container1" ref={r=> this.myContainer1=r} >
                        <AppBar position={"sticky"}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit"
                                            onClick={() => this.setState({show:false})}
                                            aria-label="close">
                                    <CloseIcon/>
                                </IconButton>
                                <Typography variant="h6" style={{flexGrow: 1}}>
                                    Your Order
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <Container maxWidth="md" style={{padding: 15, marginBottom: 80}}>
                            {Cart.instance.cart.map((food) => (
                                <div style={{
                                    position: "relative",
                                    paddingBottom: 12,
                                    borderBottom: "dotted 1px"
                                }} key={food.hash}>
                                    <div style={{
                                        position: "absolute",
                                        right: 0,
                                        top: 0,
                                        display: "flex",
                                        flexDirection: "row"
                                    }}>
                                        <span>{Util.instance.formatPrice(food.total * food.count)}</span>
                                        <IconButton aria-label="remove-from-cart"
                                                    style={{padding: 2, zIndex: 100}} key="food.foodid"
                                                    onClick={() => Cart.instance.removeFromCart(food)}>
                                            <DeleteForeverIcon/>
                                        </IconButton>
                                    </div>
                                    <div style={{position: "relative", left: 0, top: 0}}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            {Util.instance.padnumber(food.count, 2)}x {food.title}</Typography>
                                        <Typography variant="caption">
                                            {food.toppings ?
                                                food.toppings.filter((t) => t.checked * 1 === 1).map((topping) => (
                                                    `${topping.title}`
                                                )).join(' ◦ ')
                                                : ""
                                            }
                                        </Typography>

                                    </div>
                                </div>
                            ))}
                            <div style={{
                                position: "relative",
                                paddingBottom: 12,
                                borderTop: "dotted 3px white"
                            }}>
                                <div style={{
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "right"
                                }}>
                                    <span>Subtotal: {Util.instance.formatPrice(Cart.instance.subtotal)}</span>
                                    {
                                        Cart.instance.discount > 0 &&
                                        <span>Discount: {Util.instance.formatPrice(Cart.instance.discount)}</span>
                                    }
                                    {
                                        Cart.instance.deliveryCharge > 0 &&
                                    <span>Delivery Charge : {Util.instance.formatPrice(Cart.instance.deliveryCharge)}</span>
                                    }
                                    {
                                        Site.instance.site.stripe_service_charge > 0 &&
                                        <span>Service Charge : {Util.instance.formatPrice(Cart.instance.serviceCharge)}</span>
                                    }
                                    <span
                                        style={{fontWeight: "bold"}}>Total: {Util.instance.formatPrice(Cart.instance.totalCartValue)}</span>
                                </div>
                            </div>
                        </Container>
                        <DialogActions style={{ position:"sticky", bottom:0,zIndex: 200,
                            marginTop: 0,
                            borderTop: "1px dotted red",
                            textAlign:"center",
                            backgroundColor:"white",
                            height:40}}>
                                <Button style={{position: "absolute", left: 0}} color={"secondary"}
                                        onClick={() => {
                                            this.setState({show:false})
                                        }}>< ArrowBackIosIcon/> MENU </Button>
                                <Box component="span" m={1} style={{ margin: "0 auto"}}>
                                    {Util.instance.formatPrice(Cart.instance.totalCartValue)}
                                </Box>
                                <Button style={{position: "absolute", right: 0}} color={"secondary"}
                                        disabled={Cart.instance.totalCartValue===0}
                                        onClick={() => {
                                            this.handleCheckout();
                                        }}>CHECKOUT < ArrowForwardIosIcon/></Button>
                        </DialogActions>
                            </Container></Grid>
                    </Dialog>
        );
    }
}

export default CartDialog;
