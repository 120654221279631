import React from 'react';
import Button from '@material-ui/core/Button';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    Drawer,
    TextField,
    ListItemSecondaryAction,
    Switch, AppBar, Toolbar, IconButton, Typography, Grid, Container, Snackbar
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import CloseIcon from "@material-ui/icons/Close";
import Cart from "./service/Cart";
import Util from "./service/Util";
import Site from "./service/Site";

import {Alert, ToggleButtonGroup, ToggleButton} from "@material-ui/lab";
import styled from "@material-ui/core/styles/styled";

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({

    "&.Mui-selected": {
        color: "#fff",
        backgroundColor: "#50B848",
        "&:hover": {
            color: "#fff",
            backgroundColor: "#50B848",
        },
    },
    "&:hover": {
        color: "#fff",
        backgroundColor: "#50B848",
    },
}));

class DeliveryDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            useDifferentAddress: false,
            showDeliveryDrawer: false,
            showPaymentDrawer: false,
            show:false,
            cartChanged:'',
            deliveryAddress_postcode:'',
            deliveryAddress_address1:'',
            deliveryAddress_address2:'',
            deliveryAddress_person:'',
            deliveryAddress_city:'',
            deliveryAddress_addressnote:'',
            deliveryAddress_phone:'',
            deliveryMethod: '',
        }
    }

    componentDidMount() {

        Cart.instance.showDialogSubject.subscribe((e)=>{
            if (e.name && (e.name==="DeliveryDialog" || e.name==="*")) {
                this.setState({show: e.show, deliveryMethod: ''}, ()=>{
                    this.changeDeliveryMethod(this.defaultDeliveryMethod(), false);
                });
                //console.log(Site.instance.site.delivery_methods==null || Site.instance.site.delivery_methods==='' || Site.instance.site.delivery_methods.toLowerCase().includes("collection"))
            }
        })
        Cart.instance.cartSubject.subscribe(()=>{
//            console.log("deliveryCharge " + Cart.instance.deliveryCharge);
                this.setState({cartChanged:''});
        })
        Site.instance.authSubject.subscribe(()=>{
            if (!Site || !Site.instance || !Site.instance.site || !Site.instance.site.payment) return;
            this.changeDeliveryMethod(this.defaultDeliveryMethod(), false);
            if (Site.instance.site.payment.toLowerCase().includes("cash"))
                this.changePaymentMethod("Cash");
            if (Site.instance.site.payment.toLowerCase().includes("paypal") || Site.instance.site.payment.toLowerCase().includes("stripe"))
                this.changePaymentMethod("Online");
            this.setState({cartChanged:''},()=>Cart.instance.updateTotal());
        })
    }

    defaultDeliveryMethod(){
        if (Site.instance.site && Site.instance.site.delivery_methods!=null && Site.instance.site.delivery_methods.toLowerCase().includes("collection") && !Site.instance.site.delivery_methods.toLowerCase().includes("delivery"))
            return "Collection"
        return "Delivery"
    }
    handlePlaceOrder() {
        if (this.state.deliveryMethod==''){
            Site.instance.showToast("Choose delivery method", "error", "bottom")
            return false;
        }
        if (!Cart.instance.checkBeforePlaceOrder()){
            return false;
        }
        if (this.state.useDifferentAddress){
            if (!Site.instance.checkPostCode(this.state.deliveryAddress_postcode)){
                Site.instance.showToast("Invalid postcode", "error", "bottom")
                return false;
            }
            Cart.instance.deliveryAddress.postcode=this.state.deliveryAddress_postcode;
            Cart.instance.deliveryAddress.address1=this.state.deliveryAddress_address1;
            Cart.instance.deliveryAddress.address2=this.state.deliveryAddress_address2;
            Cart.instance.deliveryAddress.person=this.state.deliveryAddress_person;
            Cart.instance.deliveryAddress.city=this.state.deliveryAddress_city;
            Cart.instance.deliveryAddress.addressnote=this.state.deliveryAddress_addressnote;
            Cart.instance.deliveryAddress.phone=this.state.deliveryAddress_phone;
        }else{
            Cart.instance.deliveryAddress.postcode='';
            Cart.instance.deliveryAddress.address1='';
            Cart.instance.deliveryAddress.address2='';
            Cart.instance.deliveryAddress.person='';
            Cart.instance.deliveryAddress.city='';
            Cart.instance.deliveryAddress.addressnote='';
            Cart.instance.deliveryAddress.phone='';
        }
        Cart.instance.updateTotal();
        if (Cart.instance.deliveryCharge>-1) {
            this.setState({show: false})
            Cart.instance.showDialogSubject.next({
                name: "PaymentDialog",
                show: true
            })
        }else{
            Site.instance.showToast("Sorry! We cannot deliver to your address.", "error", "bottom")
        }

        return false;
    }

    changeDeliveryMethod(method, alert=false) {
        if (!method || method=='') return
        this.setState({deliveryMethod: method,showDeliveryDrawer: false}, ()=> {
            Cart.instance.changeDeliveryMethod(method);
            Cart.instance.updateTotal();
            if (alert)
                Site.instance.showMessageBox("You have chosen order for " + method, method, "")
        })
    }
    changePaymentMethod(method) {
        Cart.instance.changePaymentMethod(method);
        Cart.instance.updateTotal();

        this.setState({showPaymentDrawer: false});
    }

    render()
        {
            let auth = Site.instance.auth;
            return (
                this.state.show &&
                        <React.Fragment>
                        <Dialog open fullWidth={true} onClose={() => this.setState({show:false})}
                                className="minWidth300"
                                style={{maxWidth: '960px', margin: '0 auto', minWidth:300}}
                        >
                            <AppBar position={"static"}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit"
                                                onClick={() => this.setState({show:false})}
                                                aria-label="close">
                                        <CloseIcon/>
                                    </IconButton>
                                    <Typography variant="h6" style={{flexGrow: 1}}>
                                        Checkout
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                <List>
                                    <ListItem    selected
                                              style={{ marginBottom: 5, marginTop: 5}}>
                                        <ListItemText primary={
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{flexGrow: 1, fontWeight: "bold"}}>Order for</div>
                                                <ToggleButtonGroup
                                                    color="primary"
                                                    value={this.state.deliveryMethod}
                                                    exclusive
                                                    onChange={(e,v)=>{this.changeDeliveryMethod(v,true)}}>
                                                    {(Site.instance.site.delivery_methods==null ||  Site.instance.site.delivery_methods==='' || Site.instance.site.delivery_methods.toLowerCase().includes("delivery")) && <CustomToggleButton value="Delivery">Delivery</CustomToggleButton>}
                                                    {(Site.instance.site.delivery_methods==null ||  Site.instance.site.delivery_methods==='' || Site.instance.site.delivery_methods.toLowerCase().includes("collection")) && <CustomToggleButton value="Collection">Collection</CustomToggleButton>}

                                                </ToggleButtonGroup>

                                                {/*<span style={{ fontWeight: "bold" }}>{Cart.instance.deliveryMethod}</span>
                                                <ArrowForwardIosIcon/>*/}
                                            </div>
                                        }/>
                                    </ListItem>
                                    <ListItem button selected onClick={() => this.setState({showPaymentDrawer: true})}
                                              style={{ marginBottom: 5, marginTop: 5}}>
                                        <ListItemText primary={
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{flexGrow: 1}}>Payment Method</div>
                                                <span>{Cart.instance.paymentMethod}</span>
                                                <ArrowForwardIosIcon/>
                                            </div>
                                        }/>
                                    </ListItem>
                                    { Cart.instance.deliveryCharge > 0 &&
                                    <ListItem selected style={{marginBottom: 5, marginTop: 5}}>
                                        <ListItemText primary={
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{flexGrow: 1}}>Delivery Charge</div>
                                                <span>{Util.instance.formatPrice(Cart.instance.deliveryCharge)}</span>
                                            </div>
                                        }/>
                                    </ListItem>
                                    }

                                    <ListItem selected>
                                        <ListItemText primary={Cart.instance.deliveryMethod === "Collection" ? "Collection from Store" : "Delivery to"}
                                                      secondary=
                                                          {
                                                              Cart.instance.deliveryMethod === "Collection" ?
                                                                  <span style={{whiteSpace: "pre-line"}}>{Site.instance.sitename} {"\n"}{Site.instance.site.address}
                                                                      {"\n"}{Site.instance.site.tel}
                                                                  </span>
                                                                  :
                                                                  <React.Fragment>
                                                          {!this.state.useDifferentAddress &&
                                                              <React.Fragment>
                                                                  <span
                                                              style={{whiteSpace: "pre-line"}}>{auth.firstname} {auth.lastname} {"\n"}
                                                              {auth.flatnumber} {auth.housename} {auth.housenumer}
                                                              {auth.street} {auth.town}{"\n"}
                                                              {auth.postcode}{"\n"}
                                                              {auth.addressnotes}
                                                              </span>

                                                              </React.Fragment>
                                                          }
                                                          {this.state.useDifferentAddress &&
                                                              <React.Fragment>
                                                                  <form noValidate >
                                                                  <TextField
                                                                      autoFocus
                                                                      margin="dense"
                                                                      label="Name"
                                                                      type="text"
                                                                      fullWidth
                                                                      size="small"
                                                                      value={this.state.deliveryAddress_person}
                                                                      onChange={(e)=>{
                                                                          e.preventDefault();
                                                                          this.setState({deliveryAddress_person: e.target.value});
                                                                      }}
                                                                  />
                                                                  <TextField
                                                                      margin="dense"
                                                                      label="Address 1"
                                                                      type="text"
                                                                      fullWidth
                                                                      size="small"
                                                                      value={this.state.deliveryAddress_address1}
                                                                      onChange={(e)=>{
                                                                          e.preventDefault();
                                                                          this.setState({deliveryAddress_address1: e.target.value});
                                                                      }}
                                                                  />
                                                                  <TextField
                                                                      margin="dense"
                                                                      label="Address 2"
                                                                      type="text"
                                                                      fullWidth
                                                                      size="small"
                                                                      value={this.state.deliveryAddress_address2}
                                                                      onChange={(e)=>{
                                                                          e.preventDefault();
                                                                          this.setState({deliveryAddress_address2: e.target.value});
                                                                      }}
                                                                  />
                                                                  <TextField
                                                                      margin="dense"
                                                                      label="City"
                                                                      type="text"
                                                                      size="small"
                                                                      fullWidth
                                                                      value={this.state.deliveryAddress_city}
                                                                      onChange={(e)=>{
                                                                          e.preventDefault();
                                                                          this.setState({deliveryAddress_city: e.target.value});
                                                                      }}
                                                                  />
                                                                  <TextField
                                                                      margin="dense"
                                                                      label="Postcode"
                                                                      type="text"
                                                                      size="small"
                                                                      fullWidth
                                                                      value={this.state.deliveryAddress_postcode}
                                                                      onChange={(e)=>{
                                                                          e.preventDefault();
                                                                          this.setState({deliveryAddress_postcode: e.target.value});
                                                                      }}
                                                                  />
                                                                  <TextField
                                                                      margin="dense"
                                                                      label="Phone"
                                                                      type="text"
                                                                      fullWidth
                                                                      size="small"
                                                                      onChange={(e)=>{
                                                                          e.preventDefault();
                                                                          this.setState({deliveryAddress_phone: e.target.value});
                                                                      }}
                                                                      value={this.state.deliveryAddress_phone}
                                                                  />
                                                                  <TextField
                                                                      margin="dense"
                                                                      label="Address Note"
                                                                      type="text"
                                                                      fullWidth
                                                                      size="small"
                                                                      value={this.state.deliveryAddress_addressnote}
                                                                      onChange={(e)=>{
                                                                          e.preventDefault();
                                                                          this.setState({deliveryAddress_addressnote: e.target.value});
                                                                      }}
                                                                  />
                                                                  </form>
                                                              </React.Fragment>
                                                          }
                                                                  </React.Fragment>
                                                          } />

                                    </ListItem>
                                    {Cart.instance.deliveryMethod === "Delivery" &&
                                    <ListItem selected>
                                        <ListItemText primary={
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{flexGrow: 1}}></div>
                                                <Button onClick={() => {
                                                    Cart.instance.showDialogSubject.next({name: "ProfileDialog", show: true})
                                                }}>Update Address
                                                    <ArrowForwardIosIcon/>
                                                </Button>
                                            </div>
                                        }/>
                                    </ListItem>
                                    }
                                    {
                                        false && Cart.instance.deliveryMethod === "Delivery" &&
                                        <ListItem selected>
                                            <ListItemText primary="Use Different Address" />
                                            <ListItemSecondaryAction>
                                                <Switch
                                                    edge="end"
                                                    onChange={() => {
                                                            this.setState({useDifferentAddress: !this.state.useDifferentAddress})
                                                        }
                                                    }
                                                    checked={this.state.useDifferentAddress}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    }
                                </List>
                                {Cart.instance.deliveryCharge === -1 &&
                                <Alert  severity="error">Sorry! We cannot deliver to your address.</Alert>
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button color="secondary" onClick={() => {
                                    this.setState({show:false})
                                }}>Cancel</Button>
                                <Button color="secondary" onClick={() => {
                                    return this.handlePlaceOrder()
                                }} disabled={this.state.deliveryMethod==='' || Cart.instance.deliveryCharge===-1 || Cart.instance.cart.totalCartValue<=0}>
                                    { Cart.instance.paymentMethod==="Online"?
                                        "Pay": "Place Order"}
                                </Button>
                            </DialogActions>
                        </Dialog>
                            <Drawer anchor="bottom"
                                    style={{ maxWidth: '960px'}}
                                    open={this.state.showDeliveryDrawer}
                                    onClose={() => this.setState({showDeliveryDrawer: false})}
                                    PaperProps={{ style: { maxWidth: 500, margin: "0 auto"}}}
                            >

                                <List>
                                    {Site.instance.site.delivery_methods==null || Site.instance.site.delivery_methods==='' || Site.instance.site.delivery_methods.toLowerCase().includes("delivery") ?
                                        <ListItem button onClick={() => this.changeDeliveryMethod("Delivery", true)}>
                                            <ListItemText primary={
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <div style={{flexGrow: 1}}>Delivery to My Address</div>
                                                    {Cart.instance.deliveryMethod === "Delivery" &&
                                                    <CheckOutlinedIcon color="disabled"/>
                                                    }
                                                </div>
                                            }/>
                                        </ListItem>
                                    : "" }
                                    {Site.instance.site.delivery_methods==null || Site.instance.site.delivery_methods==='' || Site.instance.site.delivery_methods.toLowerCase().includes("collection") ?
                                        <ListItem button onClick={() => this.changeDeliveryMethod("Collection", true)}>
                                            <ListItemText primary={
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <div style={{flexGrow: 1}}>Collection from Restaurant</div>
                                                    {Cart.instance.deliveryMethod === "Collection" &&
                                                    <CheckOutlinedIcon color="disabled"/>
                                                    }
                                                </div>
                                            }/>
                                        </ListItem>
                                    :""}
                                </List>
                            </Drawer>
                            <Drawer anchor="bottom"
                                    open={this.state.showPaymentDrawer}
                                    onClose={() => this.setState({showPaymentDrawer: false})}
                                    PaperProps={{ style: { maxWidth: 500, margin: "0 auto"}}}
                            >
                                <List>
                                    {(Site.instance.site.payment.toLowerCase().includes("paypal") || Site.instance.site.payment.toLowerCase().includes("stripe")) &&
                                    <ListItem button onClick={() => this.changePaymentMethod("Online")}>
                                        <ListItemText primary={
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{flexGrow: 1}}>Pay Online</div>
                                                {Cart.instance.paymentMethod === "Online" &&
                                                <CheckOutlinedIcon color="disabled"/>
                                                }
                                            </div>}/>
                                    </ListItem>
                                    }
                                    {Site.instance.site.payment.toLowerCase().includes("cash") &&
                                    <ListItem button onClick={() => this.changePaymentMethod("Cash")}>
                                        <ListItemText primary={
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{flexGrow: 1}}>Cash</div>
                                                {Cart.instance.paymentMethod === "Cash" &&
                                                <CheckOutlinedIcon color="disabled"/>
                                                }
                                            </div>
                                        }/>
                                    </ListItem>
                                    }
                                </List>
                            </Drawer>

                        </React.Fragment>
            );
        }
}

export default DeliveryDialog;
