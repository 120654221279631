import {Subject} from 'rxjs';
import Util from './Util';
import crypto from 'crypto';

import {errorObject} from "rxjs/internal-compatibility";
import Cart from "./Cart";

const SITE_KEY = ''; //''GJ67FE9' //'YZ54Y23' //'E6SA6T3'; //'YZ54Y23';

export default class Site {
    static instance = Site.instance || new Site()

    sitekey = "";
    site = {
        images: [],
        logo:''
    };
    foodinfo = {
        types: [],
        offers: [],
        foods: [],
        toppingprice: [],
        foodtcategory: []
    }
    auth = {};
    siteSubject = new Subject();
    siteStatusSubject = new Subject();
    siteOpenOrdersSubject = new Subject();
    foodSubject = new Subject();
    authSubject = new Subject();
    fatalErrorSubject = new Subject();
    toastSubject = new Subject();
    messageBoxSubject = new Subject();
    waitingSubject = new Subject();
    baseApiUrl = 'https://api.ramtd.net/api/json';
    baseApiUrls = ['https://api.ramtd.net/api/json'];//, 'https://ramtd.net/api/json'
    baseApiUrls2 = [];
    resp1 = null;

    checkApiUrls(){
        let hostname=window.location.hostname
        let apionhost="https://" + window.location.hostname + "/api/json"
        if (hostname !== 'localhost' &&
            hostname.indexOf('192.') === -1 &&
            (hostname.match(/\./g) || []).length>2 &&
            this.baseApiUrls.indexOf(apionhost)===-1
        ) {
            this.baseApiUrls.push(apionhost)
        }

        let promises = [];
        for (let idx in Site.instance.baseApiUrls) {
            let url = Site.instance.baseApiUrls[idx]
            promises.push(fetch(url + "/test.txt", {method:"POST"}).then((data)=>{
                Site.instance.baseApiUrls2.push( url)
                console.log("ok", url)
                data.text()
            }).catch((err)=> {
                console.log("err", url)
            }))
        }
        return Promise.all(promises).then(()=>{
            if (Site.instance.baseApiUrls2.length==0){
                setTimeout(()=>{
                    Site.instance.showToast("Error", "Error in connecting to the store")
                }, 5000)
            }else {
                if (Site.instance.baseApiUrls2.indexOf(this.baseApiUrl) === -1) {
                    Site.instance.baseApiUrl = Site.instance.baseApiUrls2[0]
                }
            }
        })
    }

    callApi(url, data) {
        let formData = new URLSearchParams();
        if (data == null) data = {};
        data._k = this.sitekey=='localhost'? 'classico-pizzas.co.uk' : this.sitekey;
        data._hj = window.hj && window.hj.globals && window.hj.globals.get("userId") ? window.hj.globals.get("userId") : "";
        if (this.isLogin()) {
            data._uu = this.auth._u.username;
            data._ui = this.auth.customerid;
            data._up = crypto.createHash('md5').update(this.auth._u.pass).digest('hex');
        }
        data._t = Date.now() + '';
        let _str = JSON.stringify(data).replace(/"/g, '')
        //console.log(_str)
        let _h = crypto.createHash('md5').update(_str).digest('hex');
        data._h = _h;

        for (let f in data) {
            formData.append(f, data[f]);
        }
        let promise = fetch(this.baseApiUrl + url, {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/x-www-form-urlencoded'
            }, body: formData
        })
        return promise.then(resp =>  resp.text() )
            .then(resp=> {
                this.resp1=resp
                let res2=null
                try {
                    res2=JSON.parse(resp);
                } catch (e) {
                    this.catchErrorInApi(e, {formData: formData, response: resp,url:this.baseApiUrl + url})
                }
                return res2
            })
    }

    constructor() {
        if (!('contains' in String.prototype)) {
            String.prototype.contains = function (str, startIndex) {
                return -1 !== String.prototype.indexOf.call(this, str, startIndex);
            };
        }
    }

    init() {
        window.onerror = (message, file, line, col, error) => {
            this.sendEventLog({
                data: JSON.stringify({
                    message: {
                        message: message,
                        file: file,
                        line: line,
                        col: col,
                        error: error.message,
                        stack: Util.instance? Util.instance.getStackTrace(): ''
                    }
                }),
                event: "pageerror"
            })
            return false;
        }

        this.sitekey = SITE_KEY;

        if (localStorage.getItem("sitekey") &&localStorage.getItem("sitekey")!=='') {
            this.sitekey = localStorage.getItem("sitekey");
        }
        if ((process.versions['electron'] && process.versions['electron']!=='') &&
            window.location.hostname !== 'localhost' &&
            window.location.hostname.indexOf('192.') === -1 &&
            (window.location.hostname.indexOf('.app.ramtd.net') > -1 ||
                window.location.hostname.indexOf('.ramtd.net') === -1)
        ) {
            this.sitekey = window.location.hostname;
        }
        if (window.location.hostname.indexOf('192.') === -1 && window.location.hostname.indexOf('.ramtd.net') === -1){
            this.sitekey = window.location.hostname;
        }
        if (this.sitekey == ''){
            this.sitekey = window.location.hostname;
        }
        this.auth = localStorage.getItem("auth") !== null ? JSON.parse(localStorage.getItem("auth")) : undefined
        if (this.isLogin()) {
            window.hj('identify', null, {email: this.auth.email, phone:this.auth.phone, customerid:this.auth.customerid})
            this.authSubject.next("login");
        }

        setInterval(() => {
            this.callApi("/site", {checkopen: 1})
                .then((data) => {
                    this.site.shopStatus = data.shopStatus;
                    this.site.shopStatusTemp = data.shopStatusTemp;
                    this.site.openorders = data.openorders;
                    this.siteStatusSubject.next("");
                    this.siteOpenOrdersSubject.next("")
                })
        }, 10000);

    }

    catchErrorInApi(e, data) {

//        if ((error+"").indexOf('Unexpected token',0)>-1) error="Connection Error";
        let err = e+""
        let errresp = this.resp1
       // this.fatalErrorSubject.next("Error: " + err)
        if (err.contains("SSL error")) {
            err = Util.instance.formatDate(new Date(), "yyyy/MM/dd hh:mm:ss Z") + " **THE DATE & TIME ON YOUR PC MAY BE INVALID. UPDATE IT PLEASE** " + err
        }
        setTimeout(() => {
            Site.instance.sendEventLog({
                data: JSON.stringify({
                    err: err,
                    resp: errresp,
                    stack: e.stack,
                    data: data
                }), event: "apierror"
            })
        }, 2000)
        this.showToast(err, "error", "bottom");
//        console.log(error);
    }

    timedOffers(){
        let t = new Date();
        if (this.foodinfo.servertimediff)
            t = new Date(t.getTime() - this.foodinfo.servertimediff)
        let times = ("0" + t.getHours()).slice(-2)   + ":" +
            ("0" + t.getMinutes()).slice(-2) + ":" +
            ("0" + t.getSeconds()).slice(-2)
        let tday = t.getDay()
        let offers = this.foodinfo.offers.filter(o=>o.starttime<=times && o.endtime>=times && ((o.daysofweek >>> tday) & 1)==1);
        return offers
    }
    fetchSiteData() {
        this.callApi("/site", {})
            .then((data) => {
                this.site = data;
                window.document.title = data.sitename;
                this.siteSubject.next("changed");
            }).catch(err => this.catchErrorInApi(err), {line: 358})
        this.callApi("/foods", {})
            .then((data) => {
                let types2 = data.types.filter(t => data.foods.filter((f) => f.typeid === t.typeid).length > 0);
                data.types = types2;
                this.foodinfo.types = data.types;
                this.foodinfo.offers = data.offers
                this.foodinfo.servertimediff = new Date().getTime() - data.servertime

                this.foodinfo.foods = data.foods;
                this.foodinfo.toppingprice = data.toppingprice;
                this.foodinfo.foodtcategory = data.foodtcategory;
                this.foodSubject.next("loaded");
                Cart.instance.loadFromStorage()

            }).catch(err => this.catchErrorInApi(err))
    }

    logout() {
        this.auth = {};
        localStorage.removeItem("auth");
        localStorage.removeItem("sitekey");
        localStorage.removeItem("cart");
        window.location.reload();
        this.authSubject.next("logout");
    }

    sendPassRecoveryCode(data, resultCallback) {
        data.recovery = 1;
        this.callApi("/auth", data)
            .then(res => {
                console.log(res);
                if (res.error) {
                    this.showToast("Password Recovery error " + res.error, "error", "bottom");
                    resultCallback(0, res.error);
                } else {
                    this.showToast("Recovery Code sent to your email address", "success", "bottom")
                    resultCallback(1, "OK");
                }
            }).catch(err => this.catchErrorInApi(err))
    }

    changePassword(data, resultCallback) {
        data.changepass = 1;
        this.callApi("/auth", data)
            .then(res => {
                if (res.error) {
                    this.showToast(res.error, "error", "bottom");
                    resultCallback(0, res.error);
                } else {
                    this.showToast("Your password has been set successfully", "success", "bottom")
                    resultCallback(1, "OK");
                }
            }).catch(err => this.catchErrorInApi(err))
    }

    registerUser(data, resultCallback) {
        data.reg = 1;
        this.callApi("/auth", data)
            .then(res => {
                console.log(res);
                if (res.error) {
                    this.showToast("Register error " + res.error, "error", "bottom");
                    resultCallback(0, res.error);
                } else {
                    this.showToast("Registration Successful", "success", "bottom")
                    this.checkAuth(data.email, data.password, resultCallback)
                }
            }).catch(err => this.catchErrorInApi(err))
    }

    updateUserProfile(data, resultCallback) {
        data.update = 1;
        this.callApi("/auth", data)
            .then(res => {
                if (res.error) {
                    this.showToast("Update error " + res.error, "error", "bottom");
                    resultCallback(0, res.error);
                } else {
                    this.showToast("Update Successful", "success", "bottom")
                    this.checkAuth(data.email, data.password, resultCallback, true)
                }
            }).catch(err => console.log(err))
    }

    checkAuth(email, pass, resultCallback, silent = false) {
        let data = {
            "email": email,
            "pass": pass
        }
        this.callApi("/auth", data)
            .then(res => {
                console.log(res);
                if (res.error) {
                    localStorage.removeItem("auth");
                    if (!silent) this.showToast("Login error " + res.error, "error", "bottom");
                    resultCallback(0, res.error);
                } else {
                 window.hj('identify', null, {email: email, phone:res.phone, customerid:res.customerid})
                    res._u = {username: email, pass: pass};
                    this.auth = res;
                    localStorage.setItem("auth", JSON.stringify(res));
                    this.authSubject.next("login");
                    if (!silent) this.showToast("Login Successful", "success", "bottom");
                    resultCallback(1, "OK");
                }
            }).catch(err => this.catchErrorInApi(err, {line: 311}))

    }

    fetchOrderHistory(resultCallback) {
        if (!this.isLogin()) return;
        this.callApi("/orders", {"customerid": this.auth.customerid})
            .then(res => {
                console.log(res);
                if (res.error) {
                    this.showToast("Error " + res.error, "error", "bottom");
                    resultCallback(0, null);
                } else {
                    resultCallback(1, res);
                }
            }).catch(err => this.catchErrorInApi(err), {line:326})
    }

    getCustomerAddress() {
        if (this.isLogin()) {
            let str = Util.str(this.auth.firstname) + " " + Util.str(this.auth.lastname) + ", " +
                Util.str(this.auth.flatnumber) + " " + Util.str(this.auth.housenumber) + " " +
                Util.str(this.auth.housename) + " " + Util.str(this.auth.street) + ", " + Util.str(this.auth.town) +
                ", " + Util.str(this.auth.postcode);
            str = str.replace('  ', ' ')
                .replace('  ', ' ')
                .replace('  ', ' ')
                .replace(',,', ',');
            return str;

        } else {
            return "";
        }
    }

    getOrderId(data, resultCallback) {
        data.getorderid = 1;
        data.total = Cart.instance.totalCartValue;

        this.callApi("/order", data)
            .then(res => {
                if (res.error) {
                    this.showToast(res.error, "error", "bottom");
                    resultCallback(0, res.error);
                } else {
                    resultCallback(res.orderid, res.error);
                }
            }).catch(err => this.catchErrorInApi(err, {line: 358}))
    }

    completeOrder(orderid, orderParams, resultCallback) {
        let data = {}
        data.orderid = orderid;
        let cart2 = JSON.parse(JSON.stringify(Cart.instance.cart));
        cart2.forEach((f) => {
            if (f.prices) delete f.prices;
            if (f.toppings) delete f.toppings;
            //if (f.title) delete f.title;
            if (f.description) delete f.description;
            if (f.details) {
                f.details.forEach((d) => {
                    if (d.title) delete d.title;
                    if (d.description) delete d.description;
                    if (d.selectedFood) {
                        if (d.selectedFood.prices) delete d.selectedFood.prices;
                        if (d.selectedFood.toppings) delete d.selectedFood.toppings;
                    }
                })
            }
        })

        data.order = JSON.stringify(cart2);
        data.payment = JSON.stringify(orderParams);
        data.address = JSON.stringify(Cart.instance.deliveryAddress);
        data.deliveryMethod = Cart.instance.deliveryMethod;
        data.paymentMethod = Cart.instance.paymentMethod;
        data.totalCartValue = Cart.instance.totalCartValue;
        data.subtotal = Cart.instance.subtotal;
        data.totalCartFoodCount = Cart.instance.totalCartFoodCount;
        data.deliveryCharge = Cart.instance.deliveryCharge;
        data.discount = Cart.instance.discount;
        data.customerid = this.auth.customerid;
        data.debugOrderText = Cart.instance.debugOrderText;

        this.callApi("/order", data)
            .then(res => {
                if (!res.orderid || res.orderid === 0) {
                    resultCallback(0, res.message);
                } else {
                    Cart.instance.clearCart();
                    Cart.instance.cartSubject.next("");
                    resultCallback(res.orderid, res.message);
                }
            }).catch(err => this.catchErrorInApi(err))
        /*
                fetch("/paypal-transaction-complete", {
                    method: "post",
                    body: JSON.stringify({
                        orderID: payData.orderID
                    })
                }).then((data)=>{
                    if (data.result=="OK"){
                        //Cart.instance.cart.lengt
                    }
                    resultCallback(data.result, data.message);
                });*/

    }

    getFoodsOfType(type) {
        let typeid = this.foodinfo.types.find(t => t.title === type).typeid;
        return this.foodinfo.foods.filter((f) => f.typeid === typeid);
    }

    showToast(msg, type, position) {
        this.showWaiting(false, "")
        this.toastSubject.next({msg: msg, type: type, position: position});
    }

    showMessageBox(msg, title, type) {
        this.showWaiting(false, "")
        this.messageBoxSubject.next({msg: msg, title: title, type: type});
    }

    showWaiting(show, msg) {
        this.waitingSubject.next({msg: msg, show: show});
    }

    isLogin() {
        return this.auth && this.auth.customerid && this.customerid !== "";
    }

    checkPostCode(postcode) {
        postcode = postcode.replace(/\s/g, "");
        let regex = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i;
        return regex.test(postcode);
    }

    sendEventLog(data) {

        let _navigator = {};
        for (let i in navigator) _navigator[i] = navigator[i];
        data._nav = JSON.stringify(_navigator)

        this.callApi("/eventlog", data)
            .then((data) => {
                console.log(data);
            })
    }

    addPaypalScript() {
        if (Site.instance.site.ppclientid) {
            const script = document.createElement("script");
            script.src = "https://www.paypal.com/sdk/js?currency=GBP&client-id=" + Site.instance.site.ppclientid;
            script.async = true;
            /*
                        script.onload = () => {
                            Site.instance.sendEventLog({data: '', event: "custompploaded"})
                        }
            */
            script.onerror = () => {
                let data = {
                    stacktrace: Util.instance? Util.instance.getStackTrace(): ''
                }
                // defer to
                setTimeout(() => {
                    Site.instance.sendEventLog({data: JSON.stringify(data), event: "ppjsloaderror"})
                }, 5000)

            }
            document.body.appendChild(script);


        }
    }

    isTestOrder() {
        if (this.isLogin() && Site.instance.auth._u.username) {
            const keywords = ["ruzbahan"];
            let email = Site.instance.auth._u.username.toLowerCase();
            let ks = keywords.filter(k => {
                return email.contains(k)
            })
            return ks.length > 0
        }
        return false;
    }

}

