import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    AppBar,
    Badge,
    Toolbar,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemText,
    TextField,
    Dialog,
    BottomNavigation,
    Grid,
    Box,
    Button,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Chip,
    DialogActions
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import MenuIcon from '@material-ui/icons/Menu';
import {ShoppingBasketOutlined, Person, PersonOutline, MotorcycleOutlined} from '@material-ui/icons';
import Util from './service/Util';
import Cart from './service/Cart';
import Site from './service/Site';
import CloseIcon from "@material-ui/icons/Close";



class FoodOptionsDialog extends React.Component {
    modelabrs=[];
    sizeabrs=[];
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            totalWithOptionPrice: undefined,
            optionSize: undefined,
            optionModel: undefined,
            food:{},
            offerDetail:undefined,
            extraOfferPrice:0,
            count:1,
            fromOffers:0
        }
        Cart.instance.showDialogSubject.subscribe((e)=>{
            if (e.name==="FoodOptionsDialog"){
                let food = null;
                let offerDetail=e.extra && e.extra.offerDetail? e.extra.offerDetail : undefined;
                if (offerDetail)
                    food = JSON.parse(JSON.stringify(offerDetail.selectedFood));
                else
                    food = JSON.parse(JSON.stringify(e.extra.food));
                let sizeabrs1 = [...new Set(food.prices.map(item => ({abr: item.sizeabr, id: item.sizeid})))];
                if (offerDetail && offerDetail.sizeid && offerDetail.sizeid>0)
                    sizeabrs1 = [...new Set(food.prices.filter(s=>s.sizeid*1===offerDetail.sizeid*1).map(item => ({abr: item.sizeabr, id: item.sizeid})))];
                this.sizeabrs = [...new Set(sizeabrs1.map(o => JSON.stringify(o)))].map(s => JSON.parse(s)).filter(a=>a.id*1>0);
                let modelabrs1 = [...new Set(food.prices.map(item => ({abr: item.modelabr, id: item.modelid})))];
                this.modelabrs = [...new Set(modelabrs1.map(o => JSON.stringify(o)))].map(s => JSON.parse(s)).filter(a=>a.id*1>0);

//                console.log(this.modelabrs);
  //              console.log(this.sizeabrs);
                food.toppings && food.toppings.forEach(topping =>{
                    if (!('count' in topping)) topping.count = topping.checked * 1;
                });

                food.selectedSize=this.sizeabrs.length>0? this.sizeabrs[0] : undefined;
                food.selectedModel=this.modelabrs.length>0? this.modelabrs[0] : undefined;

                let tcats = {}
                if (food && food.toppings){
                    tcats = Util.instance.groupByKey(food.toppings, 'ttitle')
                }
                this.updateToppingPrice(food,tcats)
                this.setState({food: food, show:e.show,
                        totalWithOptionPrice:undefined,
                        fromOffers: e.extra.fromOffers,
                        tcats: tcats,
                        optionSize : food.selectedSize,
                        optionModel: food.selectedModel,
                        count:1,
                        offerDetail: offerDetail,
                        extraOfferPrice:0},
                    ()=>{
                        this.findPriceByOptions()
                    })
                ;
            }
        })
    }
    updateToppingPrice(food,ts){
        let tcats = ts ? ts : this.state.tcats
        let food_ = food ? food : this.state.food
        for(let i=0; i<Object.keys(tcats).length; i++){
            let cat = Object.keys(tcats)[i]
            for(let j=0; j<tcats[cat].length; j++){
                let p = Cart.instance.findToppingPrice(food_, tcats[cat][j], Site.instance.foodinfo.toppingprice)
                tcats[cat][j].price = p[0]
            }
        }
    }
    optionSizeSelected(e, size) {
        e.persist();
        this.state.food.selectedSize=size;
        this.updateToppingPrice()
        this.setState({optionSize: size}, () => {
            this.findPriceByOptions();
        });
    }

    optionModelSelected(e, model) {
        e.persist();
        this.state.food.selectedModel=model;
        this.updateToppingPrice()
        this.setState({optionModel: model}, () => {
            this.findPriceByOptions();
        });

    }

    clickOnTopping(e, topping) {
        e.persist();

        if (topping.maxcount && topping.maxcount>0){
            let selected = this.state.tcats[topping.ttitle].reduce((c, topp) => c + topp.count??0, 0);
            if (selected>=topping.maxcount){
                let selectedToppings = this.state.tcats[topping.ttitle].filter(x=>x.count>0)
                let lastSelectedTopping =selectedToppings[selectedToppings.length-1]
                if (lastSelectedTopping == topping && selectedToppings.length>1)
                    lastSelectedTopping =selectedToppings[selectedToppings.length-2]
                lastSelectedTopping.count--
            }
        }
        topping.count += 1
        this.setState({foodOptionDialogFood: this.state.foodOptionDialogFood}, () => {
            this.findPriceByOptions();
        });

    }
    deleteOnTopping(e, topping) {
        e.persist();
        if (topping.count > 0) topping.count = topping.count - 1
        this.setState({foodOptionDialogFood: this.state.foodOptionDialogFood}, () => {
            this.findPriceByOptions();
        });
    }

    findPriceByOptions() {
        if(this.state.food) {
            let model = this.state.optionModel;
            let size = this.state.optionSize;
            let freeToppingCount = this.state.food.freetoppingcount *1 + (this.state.offerDetail ? this.state.offerDetail.freetoppingcount *1: 0);
            let price = Cart.instance.findPriceByOptions(this.state.food, model, size, freeToppingCount);
            if (price) {
                let extra = 0;
                if (this.state.offerDetail) {
                    let origFood = Site.instance.foodinfo.foods.find(f => f.foodid === this.state.food.foodid);
                    let basePrice = Cart.instance.findPriceByOptions(origFood, {id: this.state.offerDetail.modelid*1===0? (model?model.id:undefined) :this.state.offerDetail.modelid}, {id: this.state.offerDetail.sizeid*1===0? (size?size.id:undefined) : this.state.offerDetail.sizeid}, freeToppingCount);
                    if (price > basePrice) {
                        extra = (price - basePrice);
                    }
//            console.log("Price:" + price + "/ base:" + basePrice + "////" + extra + "/model:" + model.id + " /size:" + size.id + "/offer model:" + this.state.forofferdetail.modelid + "/ offer size:" + this.state.forofferdetail.sizeid);
                    console.log("Extra: " + extra);
                }
                this.state.food.totalWithOptionPrice = Math.round(price*100)/100;
                this.state.food.extraOfferPrice = Math.round(extra*100)/100;
                this.setState({totalWithOptionPrice: price, extraOfferPrice: extra, food: this.state.food});
            }
            if (price===0)
                this.setState({totalWithOptionPrice: price, extraOfferPrice: 0, food: this.state.food});
        }
    }

    render() {
        return (
            this.state.show &&
                    <Dialog fullWidth open={true} onClose={()=>{this.setState({show:false})}}
                            className="dlg1 minWidth300"
                            style={{maxWidth: '960px', margin: '0 auto', position:"fixed"}}
                    >
                        <AppBar position="sticky" styl={{ display: "block"}}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit"
                                            onClick={()=>{this.setState({show:false})}}
                                            aria-label="close">
                                    <CloseIcon/>
                                    </IconButton>
                                <Typography variant="h6" style={{flexGrow: 1}}> Food Options
                                </Typography>
                            </Toolbar>
                        </AppBar>



                        <Box style={{marginBottom: 10, padding: 15}} textAlign={"left"}>
                            <Typography variant="h6" align={"center"} width={1}>
                                {this.state.food.title}
                            </Typography>
                            {
                                this.state.food.toppings && this.state.food.toppings.filter(t=>t.checked*1>0).length>0 &&
                                <Typography variant="h6"  style={{fontSize: "small"}} align={"center"} width={1}>
                                    { this.state.food.toppings.filter(t=>t.checked*1>0).map(t=>t.title).join(', ')}
                                </Typography>
                            }
                                {this.modelabrs.length > 0 && <React.Fragment>
                                    <FormLabel component="legend">Model</FormLabel>
                                    <RadioGroup row defaultValue="top">
                                        {
                                            this.modelabrs.map((model,index) => (
                                                <FormControlLabel
                                                    value={model.abr}
                                                    control={<Radio color="primary"/>}
                                                    label={model.abr}
                                                    labelPlacement="end"
                                                    key={model.id}
                                                    checked={this.state.optionModel===model}
                                                    onChange={(e) => this.optionModelSelected(e, model)}
                                                />
                                            ))
                                        }
                                    </RadioGroup>
                                </React.Fragment>
                                }
                                {this.sizeabrs.length > 0 && <React.Fragment>
                                    <FormLabel component="legend">Size</FormLabel>
                                    <RadioGroup row  defaultValue="top">
                                        {
                                            this.sizeabrs.map((size,index) => (
                                                <FormControlLabel
                                                    value={size.abr}
                                                    control={<Radio color="primary"/>}
                                                    label={size.abr}
                                                    labelPlacement="end"
                                                    key={size.id}
                                                    checked={this.state.optionSize===size}
                                                    onChange={(e) => this.optionSizeSelected(e, size)}
                                                />
                                            ))
                                        }
                                    </RadioGroup>
                                </React.Fragment>
                                }
                                {
                                    this.state.food.toppings && this.state.food.toppings.length > 1 &&
                                        <Box style={{marginTop: 20, marginBottom:10}} width={1}>
                                    <FormLabel component="legend">Toppings</FormLabel>
                                    <Box align={"center"} className={"topping-chips"}>
                                        {
                                            Object.keys(this.state.tcats).map((ttitle) => (
                                            <React.Fragment>
                                            <FormLabel component="legend" style={{marginLeft:"5px", marginTop: "10px", fontWeight:"bold", padding: "7px", color:"#3f51b5"}}>{ttitle}</FormLabel>
                                        {
                                            this.state.tcats[ttitle].map((topping) => (
                                            <Badge key={topping.toppingid} badgeContent={topping.count}
                                            component={"div"}
                                            color="secondary" invisible={topping.count < 2}
                                            style={{margin: 5, width: '95%'}}>
                                            <Chip
                                            variant={topping.count > 0 ? undefined : "outlined"}
                                            component={"div"}
                                            style={{width: "100%"}}
                                            label={topping.title + "     " + (topping.price>0? "£" + topping.price : "")}
                                            color={topping.count > 0 ? "primary" : undefined}
                                            onClick={(e) => {
                                            this.clickOnTopping(e, topping)
                                        }}
                                            onDelete={topping.count > 0 ? (e) => {
                                            this.deleteOnTopping(e, topping)
                                        } : undefined}
                                            />
                                            </Badge>
                                            ))
                                        }
                                            </React.Fragment>
                                            ))
                                        }
                                    </Box>
                                        </Box>
                                }
                                <TextField
                                    label="Note"
                                    variant="outlined"
                                    size="small"

                                    onInput = {(e) =>{
                                        e.target.value = e.target.value.slice(0,99)
                                    }}
                                    inputRef={e => this.note = e}
                                    style={{marginLeft:20, marginRight:20, minWidth: "90%"}}

                                />
                                {!this.state.offerDetail && <TextField
                                    label="Quantity"
                                    type="number"
                                    placeholder="Quantity"
                                    margin="normal"
                                    defaultValue="1"
                                    onChange={(e)=>{
                                        e.preventDefault();
                                        let cnt = Math.max(1, e.target.value);
                                        this.state.food.count=cnt;
                                        this.setState({count: cnt})
                                    }
                                    }
                                    style={{marginLeft:20, marginRight:20, maxWidth:100}}

                                />
                                }
                        </Box>
                        <DialogActions style={{ position:"sticky", bottom:0,zIndex: 200,
                            marginTop: 0,
                            borderTop: "1px dotted red",
                            textAlign:"center",
                            backgroundColor:"white",
                            height:40}}>
{/*
                        <BottomNavigation
                            style={{
                                width: '100%',
                                position: 'sticky',
                                bottom: 0,
                                zIndex: 200,
                                marginTop: 0,
                                borderTop: "1px dotted red",
                                textAlign:"center",
                                height:40
                            }}>
*/}
                                <Box component="span" m={1} style={{margin: "0 auto"}}>
                                    {!this.state.offerDetail && this.state.totalWithOptionPrice>0 && Util.instance.formatPrice(this.state.totalWithOptionPrice*this.state.count) }
                                    {this.state.offerDetail && this.state.extraOfferPrice>0 && ("Extra " + Util.instance.formatPrice(this.state.extraOfferPrice)) }
                                </Box>
                                <Button style={{position: "absolute", right: 0}} color={"secondary"}
                                        disabled={this.state.totalWithOptionPrice===undefined && !this.state.fromOffers}
                                        onClick={() => {
                                            if (this.state.offerDetail){
                                                Cart.instance.showDialogSubject.next({
                                                    name: "OfferOptionsDialog",
                                                    show: true,
                                                    extra:{offerFood:this.state.food}
                                                })
                                            }
                                            else {
                                                this.state.food.note=this.note.value;
                                                Cart.instance.addToCartWithOptions(this.state.food);
                                            }
                                            this.setState({show: false})
                                        }}>ADD
                                    < ArrowForwardIosIcon/>
                                </Button>

                            {/*
                        </BottomNavigation>
*/}
                        </DialogActions>
                    </Dialog>

        );
    }
}

export default FoodOptionsDialog;
