import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    AppBar,
    Paper,
    Toolbar,
    Chip,
    Hidden,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    AccordionDetails,
    AccordionSummary,
    Accordion,
    LinearProgress,
    Container, Grid, MenuList, MenuItem, Button, DialogContent, CircularProgress
} from "@material-ui/core";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Util from './service/Util';
import Cart from './service/Cart';
import Site from './service/Site';
import OfferOptionsDialog from "./OfferOptionsDialog";
import FoodOptionsDialog from "./FoodOptionsDialog";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import NewReleasesOutlinedIcon from "@material-ui/icons/NewReleasesOutlined";
import SearchBar from "material-ui-search-bar";

class FoodListMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            siteChanged:'',
            showDescFoodId:0,
            shopStatus:'',
            openorders:[],
            expanded:false,
            search: ""
        }
        this.accordionrefs = {};

    }

    searchTimer=0;
    resize() {
        this.setState({expanded: window.innerWidth >= 960});
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        Site.instance.siteSubject.subscribe(()=> {
            let tel = Site.instance.site.tel || ''
            tel = tel.replace(/[\s\-,]+/g, ',')
            Site.instance.site.tel = tel
            this.setState({siteChanged: '', shopStatus: Site.instance.site.shopStatus})
        })
        Site.instance.siteOpenOrdersSubject.subscribe(()=> {
            this.setState({openorders:  Site.instance.site.openorders})
        })
        Site.instance.foodSubject.subscribe(()=> {
            this.setState({siteChanged: ''})
        })
        Cart.instance.cartSubject.subscribe(()=>{
            this.setState({cartChanged:''});
        })
        Site.instance.siteStatusSubject.subscribe(()=>{
            // site changed
            this.setState({shopStatus:Site.instance.site.shopStatus})
        })
    }

    addToCart(food){
        if (food.prices.length > 1) {
            Cart.instance.showDialogSubject.next({name: "FoodOptionsDialog", show: true, extra: {food: food}})
        }
        if (food.prices.length===1){
            Cart.instance.addToCart(food);
        }
    }

    render() {
        const { classes } = this.props;
        let foodinfo = Site.instance.foodinfo;
        let timedoffers = Site.instance.timedOffers()
        return (
            <Grid container spacing={3} style={{minHeight:"100%", marginTop:150, marginBottom:50}} className="gridNoMargin background1">
                <Hidden smDown >
                <Grid item sm={12} md={3}>
                        {/*<MenuList style={{marginBottom: 80, marginTop: 120, flexGrow: 1, position:"sticky", top: 10,
                            padding:0, borderLeft:"2px #FF9800 dotted",
                            marginLeft:10,

                        }}>*/}
                    <MenuList style={{marginBottom: 80, marginTop: 0, flexGrow: 1, position:"sticky", top: 10,
                        padding:0, borderLeft:"2px #FF9800 dotted",
                        marginLeft:10,
                        backgroundColor: "#ffffffc0",
                    }}>
                            {timedoffers.length > 0 &&
                            <MenuItem key="offer" onClick={()=> {
                                window.location.href = "#offers"
                            }}
                            style={{fontWeight:"bold"}}>Offers</MenuItem>
                            }
                            {foodinfo.types.map((foodType, i) => (
                                <MenuItem key={foodType.typeid} onClick={()=>{
                                    //window.location.href="#" + foodType.title
                                    if (this.accordionrefs[foodType.typeid]) {
                                        window.scrollTo(0, this.accordionrefs[foodType.typeid].offsetTop)
                                    }
                                }} style={{fontWeight:"bold"}}>
                                    {foodType.title}
                                </MenuItem>
                            ))}
                        </MenuList>
                </Grid>
                </Hidden>
                <Grid item xs={12} sm={12} md={5} style={{ zIndex:10}}>
                    <Paper style={{ padding: 15, textAlign: "center", minHeight: 120, position:"relative",
                        backgroundColor:"#ffffffc0"
                    }}
                           elevation={3}
                    >
                        {this.state.shopStatus!=="" && <Chip
                            key={this.state.shopStatus}
                            style={{
                                position:"absolute",
                                top:0,right:0, margin:10,
                                backgroundColor: `${Site.instance.site.shopStatus==='open'?'#26ac24':'#ff2f00'}`,
                                color: 'white'
                            }}
                            label={Site.instance.site.shopStatus==="open"?"OPEN":"CLOSED"}
                            size="small"
                            variant="default"
                            onClick={()=>{
                                Site.instance.showMessageBox(Site.instance.site.shopStatus==="open"? "We are open and ready to take your orders!" : "Sorry! We are closed.", "Message","")

                                }
                            }
                        />
                        }
                        {Site.instance.site && Site.instance.site.logo && Site.instance.site.logo!='' &&
                        <img src={Site.instance.site.logo} style={{ marginTop:"-25%", maxWidth:200}}/>
                        }
                        {(Site.instance.site.buy1get1_food_type_id!=null && Site.instance.site.buy1get1_food_type_id!='') &&
                        <Typography variant="body2" style={{display: 'flex',alignItems: 'center', justifyContent:"center", fontSize: "larger", color:"white"}} className="neon">
                            Buy 1 Get 1 Free on all Pizzas
                        </Typography>
                        }
                        {Site.instance.site.online_discount > 0 &&
                        <Typography variant="body2" style={{display: 'flex',alignItems: 'center', justifyContent:"center", fontSize: "large", color:"white"}} className="neon">
                            <NewReleasesOutlinedIcon htmlColor="#ffb300" style={{ fontSize: 30, marginRight:5 }}/>
                            {Site.instance.site.online_discount}% off online orders!
                        </Typography>
                        }
                        <Typography style={{fontWeight: "bold", marginBottom: 5}} variant="h5">
                            {Site.instance.site.siteTitle}
                        </Typography>
                        <Typography variant="body2" style={{fontWeight: "bold"}}>
                            {Site.instance.site.siteDesc !=="" ? Site.instance.site.siteDesc : ""}
                        </Typography>

                        <Typography variant="body2" style={{fontWeight: "bold"}}>
                            { Site.instance.site.openhours ??
                            (Site.instance.site.startTime? "Open Hours: " + Site.instance.site.startTime.substring(0, 5) + "-" + Site.instance.site.endTime.substring(0, 5) : "")
                            }
                        </Typography>
                        <Typography variant="body2" style={{whiteSpace: "nowrap", fontWeight:"bold"}}>
                            {Site.instance.site.minorder > 0 ? " Minimum Order: " + Util.instance.formatPrice(Site.instance.site.minorder) : ""}
                        </Typography>
                        { Site.instance.site && Site.instance.site.address &&
                        <Typography variant="body2" style={{ fontSize:"small", fontWeight:"bold"}}>
                            {Site.instance.site.address}
                        </Typography>}
                        {Site.instance.site && Site.instance.site.tel &&
                            <Typography variant="body2" style={{  fontWeight:"bold"}}>
                                <PhoneInTalkIcon fontSize={"small"} style={{verticalAlign:"middle"}}/>
                                {Site.instance.site.tel.split(',').map(t=>(
                                    <a key={t} className="anchorText" href={ 'tel:' + t}>{t}</a>
                                ))
                                }
                            </Typography>
                            }
                        {Site.instance.site && Site.instance.site.foodhygiene && Site.instance.site.foodhygiene!=='' &&
                        <img src={"/order/foodhygiene" + Site.instance.site.foodhygiene + ".png"} style={{ maxWidth:120, marginTop: 15 }} />
                        }
                        {Site.instance.site && Site.instance.site.apkfile && Site.instance.site.apkfile!=='' &&
                        <a href={Site.instance.site.apkfile} target={"_blank"}><img src={"/order/androidapp.png"} style={{ maxWidth:120, marginTop: 15 }} /></a>
                        }

                    </Paper>

                    { this.state.openorders &&
                        this.state.openorders.length>0 &&
                        this.state.openorders.map(o=>(
                        <Paper
                            style={{marginTop: 20, padding: 25, flexGrow: 1, display: "flex", flexDirection: "column",backgroundColor:"#ffffffc0"}}
                            elevation={3}
                        >
                            <div style={{display:"flex", alignItems:"center"}}>
                            <NewReleasesOutlinedIcon style={{marginRight: 10, color:"green"}} fontSize="large"/>
                                { !o.eta &&
                                <span>We have just received your order #{o.orderid}.</span>
                                }
                                { o.eta &&
                                <span>We are preparing your order {o.orderid? "#" + o.orderid: ""}. It will be {o.collection==1? "ready for collection": "delivered"} in {o.eta} minutes.</span>
                                }
                            </div>
                            <LinearProgress variant="buffer" value={0} valueBuffer={0} style={{marginTop: 15}} />
                        </Paper>
                        )
                    )
                    }
                    <Paper style={{marginTop: 20, flexGrow: 1, backgroundColor:"#ffffff00"}} elevation={3} >
                        {false && <SearchBar
                            placeholder="Search your food"
                            onChange={v => {
                                if (this.searchTimer) {
                                    clearTimeout(this.searchTimer)
                                    this.searchTimer = 0
                                }
                                this.searchTimer = setTimeout(() => {
                                    this.setState({search: v.length > 1 ? v.toLowerCase() : ""})
                                }, 1000)
                            }
                            }
                            onCancelSearch={() => this.setState({search: ""})}
                            cancelOnEscape
                        />
                        }
                        {timedoffers.length > 0 &&
                        <Accordion key={0} defaultExpanded={this.state.expanded}
                                   style={{ backgroundColor:"#ffffffc0"}}

                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" >
                                <Typography id="offers" style={{fontWeight: "bold"}}>Offers</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{padding: 0}}>
                                <List dense={true} style={{width: '100%',}}>
                                        {timedoffers.filter(o=>!this.state.search || o.title.toLowerCase().contains(this.state.search)).map((offer) => (
                                        offer.price>0 && offer.details && offer.details.length > 0 &&
                                        <ListItem key={offer.offerid} style={{width: '100%', paddingBottom:10, borderBottom:"1px #555 dotted"}}
                                                  onClick={e => {
                                                      Cart.instance.showDialogSubject.next({
                                                          name: "OfferOptionsDialog",
                                                          show: true,
                                                          extra: {offer: offer}
                                                      })
                                                  }}>
                                            <ListItemText
                                                style={{paddingRight:50}}
                                                primary={<span style={{fontWeight:"bold"}}>{offer.title}</span>}
                                                secondary=
                                                    {
                                                        <span style={{marginTop:10}}>{offer.description}</span>
                                                    }
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton edge="end"
                                                            onClick={e =>
                                                                Cart.instance.showDialogSubject.next({
                                                                    name: "OfferOptionsDialog",
                                                                    show: true,
                                                                    extra: {offer: offer}
                                                                })
                                                            }>
                                                    <span style={{fontSize: '0.9rem',marginRight: 3}}>{Util.instance.formatPrice(offer.price)}</span>
                                                    <AddCircleOutlineOutlinedIcon/>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                        }
                        {foodinfo.types.map((foodType, i) => (
                            <Accordion key={foodType.typeid} ref={ r=> this.accordionrefs[foodType.typeid]=r}
                                       defaultExpanded={this.state.expanded}
                                       style={{ backgroundColor:"#ffffffc0"}}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"

                                >
                                    <Typography id={foodType.title} style={{fontWeight: "bold"}}>{foodType.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{padding:0}}>
                                    <List dense={true} style={{ width: '100%',}}>
                                    {foodinfo.foods.filter((f)=>f.typeid===foodType.typeid)
                                        .filter(o=>!this.state.search || o.title.toLowerCase().contains(this.state.search) || o.description.toLowerCase().contains(this.state.search))
                                        .map((food) => (
                                        food.prices && food.prices.length > 0 &&  food.prices[0].price > 0 &&
                                            <ListItem
                                                    style={{width: '100%', paddingBottom:10, borderBottom:"1px #555 dotted"}}
                                                    key={food.foodid}
                                                      //conClick={e => this.setState({showDescFoodId: this.state.showDescFoodId===food.foodid ? 0 : food.foodid})}
                                                 >
                                                <ListItemText
                                                    style={{paddingRight:50}}
                                                    primary={<span style={{fontWeight:"bold"}}>{food.title}</span>
/*
                                                            {food.description? "" :
                                                                this.state.showDescFoodId===food.foodid?
                                                                    <ExpandLessIcon style={{marginBottom:-5, color:"gray"}}/>:
                                                                    <ExpandMoreIcon style={{marginBottom:-5, color:"gray"}}/>
                                                            }
*/}
                                                    secondary=
                                                        {
                                                            <span style={{marginTop:10, paddingRight:50}}>{food.description}</span>
                                                        //this.state.showDescFoodId===food.foodid? <span style={{marginTop:10}}>{food.description}</span> : ""
                                                    }
                                                />
                                                <ListItemSecondaryAction >
                                                    <IconButton
                                                        edge={"end"}
                                                                onClick={e =>
                                                                    Cart.instance.showDialogSubject.next({name: "FoodOptionsDialog", show: true, extra: {food: food}})
                                                                }>
                                                        <div style={{fontSize: '0.9rem', marginRight: 3}}>{ Util.instance.formatPrice(food.prices[0].price) }</div>
                                                        <AddCircleOutlineOutlinedIcon/>
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                    ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        ))}


                        {/*
                                                                                    <div style={{position: "relative", paddingTop: 12, paddingBottom: 12}}
                                                                                         key={food.foodid}>
                                                                                        <div style={{
                                                                                            position: "absolute",
                                                                                            right: 0,
                                                                                            top: 0,
                                                                                            display: "flex",
                                                                                            flexDirection: "column"
                                                                                        }}>
                                                                                            <IconButton aria-label="add-to-cart"
                                                                                                        style={{padding: 2, zIndex: 100}} key="food.foodid"
                                                                                                        onClick={e => app.addToCart(food, e)}>
                                                                                                <AddCircleOutlineOutlinedIcon/>
                                                                                            </IconButton>
                                                                                            <span>{food.prices && food.prices.length > 0 ? food.prices[0].price : '--'}</span>
                                                                                        </div>
                                                                                        <div style={{
                                                                                            position: "relative",
                                                                                            left: 0,
                                                                                            top: 0
                                                                                        }}>{food.title}</div>
                                                                                        {/!*
                                                                                {food.description}
                                                                                {
                                                                                    food.prices && food.prices.length>1?
                                                                                        food.prices.map((price) => (
                                                                                            <Chip label={price.modelabr+' ' + price.sizeabr + ' ' + price.price} component="a" href="#chip" clickable ></Chip>
                                                                                        ))
                                                                                        : ""
                                                                                }
                                        *!/}
                                                                                    </div>
                                                                            <Card className={useStyles.root} variant="outlined">
                                                                            <CardHeader
                                                                            action={
                                                                            <div>
                                                                                <IconButton aria-label="add-to-cart">
                                                                                    <AddCircleOutlineOutlinedIcon />
                                                                                </IconButton>
                                                                                <br/>
                                                                                $12.00
                                                                            </div>
                                                                        }
                                                                            title={food.title}
                                                                            subheader={food.description}
                                                                            />
                                                                            </Card>
*/}
                    </Paper>
            </Grid>
                <Hidden smDown >
                <Grid item sm={12} md={4} >
                        <Paper
                            style={{ flexGrow: 1, padding:20, position:"sticky", top: 10, marginRight:10,backgroundColor:"#ffffffc0"}}
                            elevation={3}>
                            <Typography variant="body1" style={{ borderBottom: "1px #aaaaaa solid"}}>
                                Your Order
                            </Typography>
                            <Container maxWidth="md" style={{padding: 15, }}>

                                {Cart.instance.cart.map((food) => (
                                    <div style={{
                                        position: "relative",
                                        paddingBottom: 12,
                                        borderBottom: "dotted 1px"
                                    }} key={food.hash}>
                                        <div style={{
                                            position: "absolute",
                                            right: 0,
                                            top: 0,
                                            display: "flex",
                                            flexDirection: "row"
                                        }}>
                                            <span style={{fontSize:"small"}}>{Util.instance.formatPrice(food.total * food.count)}</span>
                                            <IconButton aria-label="remove-from-cart"
                                                        style={{padding: 2, zIndex: 100}} key="food.foodid"
                                                        onClick={() => Cart.instance.removeFromCart(food)}>
                                                <DeleteForeverIcon/>
                                            </IconButton>
                                        </div>
                                        <div style={{position: "relative", left: 0, top: 0}}>
                                            <Typography variant="body1"  style={{fontSize:"small"}} gutterBottom>
                                                {Util.instance.padnumber(food.count, 2)}x {food.title}</Typography>
                                            <Typography variant="body2" style={{fontSize:"small"}}>
                                                {food.toppings ?
                                                    food.toppings.filter((t) => t.checked * 1 === 1).map((topping) => (
                                                        `${topping.title}`
                                                    )).join(' ◦ ')
                                                    : ""
                                                }
                                            </Typography>

                                        </div>
                                    </div>
                                ))}
                                <div style={{
                                    position: "relative",
                                    borderTop: "dotted 0px white",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        fontSize:"small",
                                        alignItems:"flex-end"
                                    }}>
                                        <span>Subtotal: {Util.instance.formatPrice(Cart.instance.subtotal)}</span>
                                        {
                                            Cart.instance.discount > 0 &&
                                            <span>Discount: {Util.instance.formatPrice(Cart.instance.discount)}</span>
                                        }
                                        {
                                            Cart.instance.deliveryCharge > 0 &&
                                            <span>Delivery Charge : {Util.instance.formatPrice(Cart.instance.deliveryCharge)}</span>
                                        }
                                        {
                                            Site.instance.site.stripe_service_charge > 0 &&
                                            <span>Service Charge : {Util.instance.formatPrice(Cart.instance.serviceCharge)}</span>
                                        }
                                        <span
                                            style={{fontWeight: "bold"}}>Total: {Util.instance.formatPrice(Cart.instance.totalCartValue)}</span>
                                            <Button style={{marginRight:-10, marginTop:15}} color={"secondary"}
                                                    disabled={Cart.instance.totalCartValue===0}
                                                    onClick={() => {
                                                        if (!Cart.instance.checkBeforeCheckoutProceed())
                                                            return;
                                                        if (Site.instance.isLogin()) {
                                                            Cart.instance.showDialogSubject.next({
                                                                name: "DeliveryDialog",
                                                                show: true
                                                            })
                                                        } else {
                                                            Cart.instance.showDialogSubject.next({
                                                                name: "LoginDialog",
                                                                show: true
                                                            })

                                                        }
                                                    }}>CHECKOUT < ArrowForwardIosIcon/></Button>

                                    </div>
                                </div>
                            </Container>

                        </Paper>
                </Grid>
                </Hidden>
    </Grid>

    );
    }
}

export default FoodListMenu;
