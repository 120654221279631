import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    AppBar,
    Badge,
    Toolbar,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemText,
    Link,
    DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, Dialog
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import MenuIcon from '@material-ui/icons/Menu';
import {ShoppingBasketOutlined, Person, PersonOutline, MotorcycleOutlined} from '@material-ui/icons';
import AppContext from '../AppContext'
import Site from './service/Site'
import Util from "./service/Util"
import Cart from "./service/Cart"


class ForgotPassDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        }
    }
    componentDidMount() {
        Cart.instance.showDialogSubject.subscribe((e)=>{
            if (e.name && e.name==="ForgotPassDialog")
                this.setState({show:e.show, mode: ''});
        })
    }

    sendRecoveryCode(){
        let email = this.emailField.value;
        if (email==="" || !Util.instance.validateEmail(email)){
            Site.instance.showToast("Please enter valid email address", "error", "bottom")
        }else {
            Site.instance.sendPassRecoveryCode({email: email}, (result, msg) => {
                if (result === 1) {
                    this.setState({mode: 'codesent'})
                }
            });
        }
    }
    resetPassword(){
        let email = this.emailField.value;
        let code = this.codeField.value;
        let password = this.password.value;
        let password2 = this.password.value;

        if (email==="" || !Util.instance.validateEmail(email)){
            Site.instance.showToast("Please enter valid email address", "error", "bottom")
        }else if (password==="" || password!==password2) {
            Site.instance.showToast("Please enter password and type it again to confirm", "error", "bottom")
        }else if (code==="") {
            Site.instance.showToast("Please enter the recovery code sent to your email address", "error", "bottom")
        }else {
            Site.instance.changePassword({email: email, code: code, password: password}, (result, msg) => {
                if (result === 1) {
                    this.setState({mode: '', show:false})
                }
            });
        }
    }
    render() {
        return (
            this.state.show &&
                    <Dialog open fullWidth onClose={() => this.setState({show:false})}>
                        <DialogTitle>Password Recovery</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="email"
                                label="Email Address"
                                type="email"
                                fullWidth
                                inputRef={e => this.emailField = e}
                                disabled={this.state.mode==='codesent'}
                            />
                            {this.state.mode === 'codesent' &&
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Recovery Code"
                                type="number"
                                fullWidth
                                inputRef={e => this.codeField = e}
                            />
                            }
                            {this.state.mode === 'codesent' &&
                            <TextField
                                margin="dense"
                                label="New Password"
                                type="password"
                                fullWidth
                                inputRef={e => this.password = e}
                            />
                            }
                            {this.state.mode === 'codesent' &&
                                <TextField
                                margin="dense"
                                label="New Password again"
                                type="password"
                                fullWidth
                                inputRef={e => this.password2 = e}
                                />
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button color="secondary" onClick={() => {
                                this.setState({show:false})
                            }}>Cancel</Button>
                            {this.state.mode === '' && <Button color="primary" onClick={() => {
                                this.sendRecoveryCode()
                            }}>Send Recovery Code</Button>
                            }
                            {this.state.mode === 'codesent' &&
                            <Button color="primary" onClick={() => {
                                this.resetPassword()
                            }}>Update Password</Button>
                            }
                        </DialogActions>
                    </Dialog>
        );
    }
}

export default ForgotPassDialog;