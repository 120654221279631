import React from 'react';
import {createMuiTheme, makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import {
    AppBar,
    Badge,
    Toolbar,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemText,
    Grid,
    Button, BottomNavigation, Snackbar, Container
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import Site from "./service/Site";


const useStyles = makeStyles((theme) => ({
    toolbar: {
        flexGrow: 1,
        color: 'white',
    },
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));
const theme = createMuiTheme({
    palette: {
        error: {
            main: "#ff604f"
        }
    }
})

class Toast extends React.Component {
    timer;
    constructor(props) {
        super(props);
        this.state={
            show:false,
            toastMsg: "",
            toastType: "",
            toastTopBottom: ""
        }
        Site.instance.toastSubject.subscribe((toast)=>{
            if (toast.msg){
                if (this.state.show) {
                    this.setState({show: false}, () => {
                        Site.instance.toastSubject.next(toast);
                    })
                    return
                }else {
                    this.setState({
                        show: true,
                        toastMsg: toast.msg,
                        toastType: toast.type,
                        toastTopBottom: toast.position
                    });
                }

            }
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.show &&

                <Snackbar open autoHideDuration={6000}
                          anchorOrigin={{vertical: this.state.toastTopBottom, horizontal: "center"}}
                          onClose={() => {
                              this.setState({show: false});
                          }}>

                    <Alert onClose={() => {
                        this.setState({show: false});
                    }} severity={this.state.toastType ? this.state.toastType : "success"}>
                        {this.state.toastMsg}
                    </Alert>
                </Snackbar>
                }
                    </React.Fragment>

    );
    }
}

export default Toast;